/* eslint-disable no-param-reassign */
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from './redux/store-schedule';

export interface cityTargetingDetailsState {
  cityTargetingDetails: any;
  cityTargetingDetailsFlag: boolean;
}

const initialState: cityTargetingDetailsState = {
  cityTargetingDetails: [],
  cityTargetingDetailsFlag: false,
};

const saveCityTargetingDetailsSlice = createSlice({
  name: 'targetingDetails',
  initialState,
  reducers: {
    saveCityTargetingDetails(state, action: PayloadAction<any>) {
      state.cityTargetingDetails = action.payload;
      state.cityTargetingDetailsFlag = action.payload;
    },
  },
});

export const {saveCityTargetingDetails} = saveCityTargetingDetailsSlice.actions;

export const selectCityTargetingDetails = (state: RootState) => state.targetingDetails.cityTargetingDetails;

export default saveCityTargetingDetailsSlice.reducer;
