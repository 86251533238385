/* eslint-disable import/no-extraneous-dependencies */
import { RJSFSchema } from '@rjsf/utils';
import i18n from 'i18n/i18n';
import { validateEmail } from 'pages/generic-product/details/custom-fields/EmailTextField';
import { validateUrl } from 'pages/generic-product/details/custom-fields/UrlTextField';
import AnchorTextLabel from 'pages/generic-product/details/custom-fields/AnchorTextLabel';
import SchedulerWidget from '../custom-fields/SchedulerWidget';
import CityTargetingDetails from "pages/generic-product/details/custom-fields/CityTargetingDetails";

const { t } = i18n;

export const LocalServiceAdsSchema: RJSFSchema | any = (data) => ({
  title: t('pages.localServiceAds.targetingTitle'),
  // eslint-disable-next-line max-len
  type: 'object',
  required: ['clientContactFirstName', 'clientContactLastName', 'clientContactPhoneNumber',
    'clientContactEmail', 'bestNumberToReachSeller'],
  properties: {
    targetingType: {
      type: 'string',
      title: t('pages.localServiceAds.targetingType.targetingTypeLabel'),
      default: data?.targetingType || '',
      enum: [
        'City, State',
        'County, State',
        'Zip/Postal Code',
      ],
    },
    areasToIncludeThatAreNotInTargetArea: {
      type: 'string',
      title: t('pages.localServiceAds.targetingType.areasToIncludeThatAreNotInTargetAreaLabel'),
      default: data?.areasToIncludeThatAreNotInTargetArea || '',
    },
    areasToIncludeThatAreInTargetArea: {
      type: 'string',
      title: t('pages.localServiceAds.targetingType.areasToIncludeThatAreInTargetAreaLabel'),
      default:  data?.areasToIncludeThatAreInTargetArea || '',
      description: t('pages.localServiceAds.targetingType.targetingTypeDescription'),
    },
    clientInformationTitle: {
      type: 'string',
      title: t('pages.localServiceAds.clientInformation.clientInformationTitle'),
    },
    clientContactFirstName: {
      type: 'string',
      title: t('pages.localServiceAds.clientInformation.clientFirstNameLabel'),
      default: data?.clientContactFirstName || '',
    },
    clientContactLastName: {
      type: 'string',
      title: t('pages.localServiceAds.clientInformation.clientLastNameLabel'),
      default: data?.clientContactLastName || '',
    },
    clientContactPhoneNumber: {
      type: 'string',
      title: t('pages.localServiceAds.clientInformation.clientContactPhoneLabel'),
      default: data?.clientContactPhoneNumber || '',
    },
    clientContactEmail: {
      type: 'string',
      title: t('pages.localServiceAds.clientInformation.clientContactEmailLabel'),
      default: data?.clientContactEmail || '',
      description: t('pages.localServiceAds.clientInformation.clientInformationDescription'),
    },
    bestNumberToReachSeller: {
      type: 'string',
      title: t('pages.localServiceAds.clientInformation.bestNumberToReachLabel'),
      default: data?.bestNumberToReachSeller || '',
    },
    solutionStrategyTitle: {
      type: 'string',
      title: t('pages.localServiceAds.solutionStrategyTitle'),
    },
    selectPreferredBidModel: {
      type: 'string',
      title: t('pages.localServiceAds.businessInformation.selectPreferredBidModelLabel'),
      default: data?.selectPreferredBidModel || '',
      enum: [
        'Set Max Per Lead',
        'Maximize Leads',
      ],
    },
    professionalServicesClient: {
      type: 'string',
      title: t('pages.localServiceAds.businessInformation.professionalServicesClientLabel'),
      default: data?.professionalServicesClient || '',
      items: {
        enum: [
          'Yes',
          'No',
        ],
      },
    },
    category: {
      type: 'string',
      title: t('pages.localServiceAds.businessInformation.categoryLabel'),
      default: data?.category || '',
    },
    categoriesListAnchorText: {
      type: 'string',
      title: t('pages.localServiceAds.businessInformation.categoryListThisLinkText'),
      enum: [`${t('pages.localServiceAds.businessInformation.categoriesListLink')}`, true],
    },
    selectTheServicesYouWouldLikeToAdvertise: {
      type: 'string',
      title: t('pages.localServiceAds.businessInformation.selectTheServiceLabel'),
      default: data?.selectTheServicesYouWouldLikeToAdvertise || '',
    },
    generalLawyerQuerySearches: {
      type: 'string',
      title: t('pages.localServiceAds.businessInformation.selectLawyerCategoryLabel'),
      default: data?.generalLawyerQuerySearches || '',
      items: {
        enum: [
          'Yes',
          'No',
        ],
      },
    },
    fullBusinessName: {
      type: 'string',
      title: t('pages.localServiceAds.businessInformation.businessNameLabel'),
      default: data?.fullBusinessName || '',
      description: t('pages.localServiceAds.businessInformation.businessNameDescription'),
    },
    businessPhoneNumber: {
      type: 'string',
      title: t('pages.localServiceAds.businessInformation.businessPhoneNumberLabel'),
      default: data?.businessPhoneNumber || '',
    },
    businessWebsite: {
      type: 'string',
      title: t('pages.localServiceAds.businessInformation.businessWebsiteLabel'),
      default: data?.businessWebsite || '',
    },
    businessOwnerFirstName: {
      type: 'string',
      title: t('pages.localServiceAds.businessInformation.businessOwnerFirstNameLabel'),
      default: data?.businessOwnerFirstName || '',
    },
    businessOwnerLastName: {
      type: 'string',
      title: t('pages.localServiceAds.businessInformation.businessOwnerLastNameLabel'),
      default: data?.businessOwnerLastName || '',
    },
    primaryClientEmailAddress: {
      type: 'string',
      title: t('pages.localServiceAds.businessInformation.primaryClientEmailAddressLabel'),
      default: data?.primaryClientEmailAddress || '',
    },
    totalNumberOfAttorneysOrLawyers: {
      type: 'string',
      title: t('pages.localServiceAds.businessInformation.totalNumberOfLawyersLabel'),
      default: data?.totalNumberOfAttorneysOrLawyers || '',
    },
    licenseInformation: {
      type: 'string',
      title: t('pages.localServiceAds.businessInformation.licenseInformationLabel'),
      default: data?.licenseInformation || '',
    },
    gmbPageUrl: {
      type: 'string',
      title: t('pages.localServiceAds.businessInformation.gmbPageURLLabel'),
      default: data?.gmbPageUrl || '',
    },
    totalNumberOfFieldworkers: {
      type: 'string',
      title: t('pages.localServiceAds.businessInformation.totalNumberOfFieldworkersLabel'),
      default: data?.totalNumberOfFieldworkers || '',
    },
    businessFoundedYear: {
      type: 'string',
      title: t('pages.localServiceAds.businessInformation.businessFoundedLabel'),
      default: data?.businessFoundedYear || '',
    },
    languagesSpoken: {
      type: 'string',
      title: t('pages.localServiceAds.businessInformation.languagesSpokenLabel'),
      default: data?.languagesSpoken || '',
    },
    professionalBusinessLicenseNumber: {
      type: 'string',
      title: t('pages.localServiceAds.businessInformation.professionalBusinessLicenseNumberLabel'),
      default: data?.professionalBusinessLicenseNumber || '',
    },
    professionalBusinessLicenseExpirationDate: {
      type: 'string',
      title: t('pages.localServiceAds.businessInformation.professionalBusinessLicenseExpirationDateLabel'),
      default: data?.professionalBusinessLicenseExpirationDate || '',
    },
    insurancePolicyDocumentsAttached: {
      type: 'string',
      title: t('pages.localServiceAds.insurancePolicyDocumentsLabel'),
      default: data?.insurancePolicyDocumentsAttached || '',
      enum: [
        'Yes',
        'No',
      ],
    },
    schedule: {
      type: 'string',
      title: t('pages.localServiceAds.schedulerWidgetComponent'),
    },
    notesOrInstructions: {
      type: 'string',
      title: t('pages.localServiceAds.notesOrInstructionsLabel'),
      default: data?.notesOrInstructions || '',
    },
  },
  dependencies: {
    targetingType: {
      oneOf: [
        {
          properties: {
            targetingType: {
              enum: ['City, State'],
            },
            cityTargetingDetails: {
              title: t('pages.localServiceAds.targetingType.cityTargetingDetailsLabel'),
              type: "string",
              description: t('pages.localServiceAds.targetingType.cityTargetingDetailsDescriptionForCityState'),
            },
          },
        },
        {
          properties: {
            targetingType: {
              enum: ['County, State'],
            },
            targetingDetailsComponentForCountyState: {
              type: 'string',
              title: t('pages.localServiceAds.targetingType.countyTargetingDetailsLabel'),
              default: data?.targetingDetailsComponentForCountyState || '',
              description: t('pages.localServiceAds.targetingType.cityTargetingDetailsDescriptionForCountryState'),
            },
          },
        },
      ],
    },
    selectPreferredBidModel: {
      oneOf: [
        {
          properties: {
            selectPreferredBidModel: {
              enum: ['Set Max Per Lead'],
            },
            professionalServicesClientSetMaxLabel: {
              type: 'string',
              title: t('pages.localServiceAds.businessInformation.professionalServicesClientSetMaxLabel'),
              default: data?.professionalServicesClientSetMaxLabel || '',
            },
          },
        },
      ],
    },
    insurancePolicyDocumentsAttached: {
      oneOf: [
        {
          properties: {
            insurancePolicyDocumentsAttached: {
              enum: ['No'],
            },
            insurancePolicyDocumentsLabelSelectedNo: {
              type: 'string',
              title: t('pages.localServiceAds.insurancePolicyDocumentsLabelSelectedNo'),
            },
          },
        },
      ],
    },
  },
});

const textFieldKeys = [
  'targetingDetailsComponentForCountyState',
  'areasToIncludeThatAreNotInTargetArea',
  'areasToIncludeThatAreInTargetArea',
  'clientContactFirstName',
  'clientContactLastName',
  'professionalServicesClientSetMaxLabel',
  'category',
  'fullBusinessName',
  'businessOwnerFirstName',
  'businessOwnerLastName',
  'totalNumberOfAttorneysOrLawyers',
  'licenseInformation',
  'totalNumberOfFieldworkers',
  'businessFoundedYear',
  'languagesSpoken',
  'professionalBusinessLicenseNumber',
];

const emailTextFieldKeys = [
  'clientContactEmail',
  'primaryClientEmailAddress',
];

const phoneNumberTextFieldKeys = [
  'clientContactPhoneNumber',
  'businessPhoneNumber',
  'bestNumberToReachSeller',
];

const headingFieldKeys = [
  'clientInformationTitle',
  'solutionStrategyTitle'];

const descriptionFieldKeys = [
  'insurancePolicyDocumentsLabelSelectedNo',
];

const dropdownFieldKeys = [
  'targetingType',
  'selectPreferredBidModel',
  'insurancePolicyDocumentsAttached',
];

const radioFieldKeys = [
  'professionalServicesClient',
  'generalLawyerQuerySearches',
];

const textAreaFieldKeys = [
  'selectTheServicesYouWouldLikeToAdvertise',
  'notesOrInstructions',
];

const urlFieldKeys = [
  'businessWebsite',
  'gmbPageUrl',
];

const anchorFieldKeys = [
  'categoriesListAnchorText',
];

const anchorFields = anchorFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: {
    'ui:widget': (props) => (
      <AnchorTextLabel {...props}
        textBeforeAnchor={t('pages.localServiceAds.businessInformation.categoryDescriptionBeforeLink')}
        textAfterAnchor={t('pages.localServiceAds.businessInformation.categoryDescriptionAfterLink')}
        break
      />
    ),
  },
}), {});

const textFields = textFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'textInputField' },
}), {});

const emailTextFields = emailTextFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'EmailTextWidget' },
}), {});

const phoneNumberTextFields = phoneNumberTextFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'phoneNumberTextField' },
}), {});

const headingFields = headingFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'headingTextLabel' },
}), {});

const descriptionFields = descriptionFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'textLabel' },
}), {});

const dropdownFields = dropdownFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'dropdown' },
}), {});

const radioFields = radioFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'radioButton' },
}), {});

const textAreaFields = textAreaFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'TextareaWidget' },
}), {});

const urlFields = urlFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'UrlTextWidget' },
}), {});

export const LocalServiceAdsUISchema = (data) => ({
  'ui:order': ['targetingType', 'cityTargetingDetails', 'targetingDetailsComponentForCountyState',
    'areasToIncludeThatAreNotInTargetArea', 'areasToIncludeThatAreInTargetArea', 'clientInformationTitle',
    'clientContactFirstName', 'clientContactLastName', 'clientContactPhoneNumber', 'clientContactEmail',
    'bestNumberToReachSeller', 'solutionStrategyTitle', 'selectPreferredBidModel',
    'professionalServicesClientSetMaxLabel', 'professionalServicesClient', 'category', 'categoriesListAnchorText',
    'selectTheServicesYouWouldLikeToAdvertise', 'generalLawyerQuerySearches', 'fullBusinessName',
    'businessPhoneNumber', 'businessWebsite', 'businessOwnerFirstName', 'businessOwnerLastName',
    'primaryClientEmailAddress', 'totalNumberOfAttorneysOrLawyers', 'licenseInformation', 'gmbPageUrl',
    'totalNumberOfFieldworkers', 'businessFoundedYear', 'languagesSpoken', 'professionalBusinessLicenseNumber',
    'professionalBusinessLicenseExpirationDate', 'insurancePolicyDocumentsAttached',
    'insurancePolicyDocumentsLabelSelectedNo', 'schedule', 'notesOrInstructions',
  ],
  professionalBusinessLicenseExpirationDate: {
    'ui:widget': 'datePickerField',
  },
  cityTargetingDetails: {
    'ui:widget': (props) => <CityTargetingDetails {...props} data={data} />,
  },
  schedule: {
    'ui:widget': (props) => <SchedulerWidget {...props} data={data} />,
  },
  ...textFields,
  ...emailTextFields,
  ...phoneNumberTextFields,
  ...headingFields,
  ...descriptionFields,
  ...dropdownFields,
  ...radioFields,
  ...textAreaFields,
  ...urlFields,
  ...anchorFields,
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const LocalServiceAdsSchemaCustomValidate = (formData: any, errors, uiSchema) => {
  if (formData?.clientContactFirstName === '') {
    errors.clientContactFirstName.addError(t('pages.localServiceAds.clientInformation.clientFirstNameError'));
    errors.addError(true);
  }
  if (formData?.clientContactLastName === '') {
    errors.clientContactLastName.addError(t('pages.localServiceAds.clientInformation.clientLastNameError'));
    errors.addError(true);
  }
  if (formData?.clientContactPhoneNumber === '') {
    errors.clientContactPhoneNumber.addError(t('pages.localServiceAds.clientInformation.clientContactPhoneError'));
    errors.addError(true);
  }
  if (formData?.bestNumberToReachSeller === '') {
    errors.bestNumberToReachSeller.addError(t('pages.localServiceAds.clientInformation.bestNumberToReachError'));
    errors.addError(true);
  }
  if (formData?.clientContactEmail !== '' && !validateEmail(formData?.clientContactEmail)) {
    errors.clientContactEmail.addError(t('pages.shoppingAds.solutionStrategy.clientsContactEmailErrorLabel'));
    errors.addError(true);
  }
  if (formData?.primaryClientEmailAddress !== '' && !validateEmail(formData?.primaryClientEmailAddress)) {
    errors.primaryClientEmailAddress.addError(t('pages.shoppingAds.solutionStrategy.clientsContactEmailErrorLabel'));
    errors.addError(true);
  }
  if (formData?.businessWebsite !== '' && !validateUrl(formData?.businessWebsite)) {
    errors.businessWebsite.addError(t('pages.genericUrlErrorMessage.urlErrorMessage'));
    errors.addError(true);
  }
  if (formData?.gmbPageUrl !== '' && !validateUrl(formData?.gmbPageUrl)) {
    errors.gmbPageUrl.addError(t('pages.genericUrlErrorMessage.urlErrorMessage'));
    errors.addError(true);
  }
  return errors;
};
