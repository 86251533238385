/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable import/no-extraneous-dependencies */
import { RJSFSchema } from '@rjsf/utils';
import i18n from 'i18n/i18n';
import TargetedEmailCheckbox
  from 'pages/generic-product/details/custom-fields/SingleCheckedCheckbox';
import FileUploader from 'pages/generic-product/details/custom-fields/FileUploader';
import { ProductTypes } from 'services/fileUploaderSlice';
import AddClickThroughUrl from 'pages/generic-product/details/custom-fields/AddClickThroughUrl';

const { t } = i18n;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const TargetedEmailSchema: RJSFSchema | any = (data, opportunity) => ({
  title: t('pages.targetedEmail.targetingTitle'),
  type: 'object',
  properties: {
    targetedEmailPackageTitle: {
      type: 'string',
      title: t('pages.targetedEmail.selectTargetedEmailPackagesLabel'),
    },
    targetedEmailPackageCore: {
      type: 'array',
      title: '',
      default: '',
      items: {
        type: 'string',
        enum: [
          t('pages.targetedEmail.inputValues.coreInputValue'),
        ],
      },
      uniqueItems: true,
    },
    targetedEmailPackageReminderRedrop: {
      type: 'array',
      title: '',
      default: '',
      items: {
        type: 'string',
        enum: [
          t('pages.targetedEmail.inputValues.reminderRedropInputValue'),
        ],
      },
      uniqueItems: true,
    },
    targetedEmailPackagePostalFile: {
      type: 'array',
      title: '',
      default: '',
      items: {
        type: 'string',
        enum: [
          t('pages.targetedEmail.inputValues.postalFileInputValue'),
        ],
      },
      uniqueItems: true,
    },
    targetedEmailPackageMatchAndFill: {
      type: 'string',
      title: '',
      enum: [
        t('pages.targetedEmail.inputValues.matchAndFill$200InputValue'),
      ],
    },
    targetedEmailPackageCreative: {
      type: 'string',
      title: '',
      enum: [
        t('pages.targetedEmail.inputValues.creativeClientProvided$200InputValue'),
        t('pages.targetedEmail.inputValues.creativeDigitalDesign$250InputValue'),
      ],
    },
    tiedToBrandAmplifier: {
      type: 'boolean',
      title: t('pages.targetedEmail.tiedToBrandAmplifierLabel'),
      default: false,
    },
    notesOrInstructions: {
      type: 'string',
      title: t('pages.targetedEmail.notesOrInstructionsLabel'),
      default: '',
    },
    advertiserTier: {
      type: 'string',
      title: '',
      default: opportunity?.account?.advertiserTier === null ? "" : opportunity?.account?.advertiserTier,
    },
  },
  // Targeted Email PackageCore dependencies
  allOf: [
    {
      if: {
        properties: {
          targetedEmailPackageCore: {
            const: [],
          },
        },
      },
      then: {
        properties: {
          previousTargetedEmailMarketingCid: {
            type: 'string',
            title: t('pages.targetedEmail.previousTargetedEmailMarketingCidLabel'),
            default: '',
          },
        },
      },
    },
  ],
  dependencies: {
    // Targeted Email PackageCore dependencies
    targetedEmailPackageCore: {
      oneOf: [
        {
          properties: {
            targetedEmailPackageCore: {
              enum: [
                [t('pages.targetedEmail.inputValues.coreInputValue')],
              ],
            },
            targetedEmailMarketingCoreTitle: {
              type: 'string',
              title: t('pages.targetedEmail.core.targetedEmailMarketingCoreTitle'),
            },
            coreBudget: {
              type: 'string',
              title: t('pages.targetedEmail.core.budgetLabel'),
              default: '',
            },
            desiredDeploymentCoreDate: {
              type: 'string',
              title: t('pages.targetedEmail.core.desiredDeploymentDateLabel'),
              default: '',
            },
            versionId: {
              type: 'string',
              title: t('pages.targetedEmail.core.versionIdLabel'),
              default: '',
            },
            quantityOfEmailsToBeSent: {
              type: 'string',
              title: t('pages.targetedEmail.core.quantityOfEmailsToBeSentLabel'),
              default: '',
            },
            fromLine: {
              type: 'string',
              title: t('pages.targetedEmail.core.fromLineLabel'),
              default: '',
            },
            subjectLine: {
              type: 'string',
              title: t('pages.targetedEmail.core.subjectLineLabel'),
              default: '',
            },
            preHeaderText: {
              type: 'string',
              title: t('pages.targetedEmail.core.preHeaderTextLabel'),
              default: '',
            },
            testSeeds: {
              type: 'string',
              title: t('pages.targetedEmail.core.testSeedsLabel'),
              default: '',
            },
            liveSeeds: {
              type: 'string',
              title: t('pages.targetedEmail.core.liveSeedsLabel'),
              default: '',
            },
            clickThroughUrl: {
              type: 'string',
              title: t('pages.targetedEmail.core.clickThroughUrlLabel'),
              default: '',
            },
            campaignIncludeTrackingPixel: {
              type: 'string',
              title: t('pages.targetedEmail.core.campaignIncludeTrackingPixelLabel'),
              enum: [
                t('pages.targetedEmail.inputValues.yesInputField'),
                t('pages.targetedEmail.inputValues.noInputField'),
              ],
              default: '',
            },
            campaignIncludeSuppression: {
              type: 'string',
              title: t('pages.targetedEmail.core.campaignIncludeSuppressionLabel'),
              enum: [
                t('pages.targetedEmail.inputValues.yesInputField'),
                t('pages.targetedEmail.inputValues.noInputField'),
              ],
              default: '',
            },
          },
          required: [
            'versionId', 'quantityOfEmailsToBeSent', 'fromLine', 'subjectLine', 'preHeaderText',
            'testSeeds', 'liveSeeds', 'clickThroughUrl', 'campaignIncludeTrackingPixel',
            'campaignIncludeSuppression',
          ],
        },
      ],
    },
    campaignIncludeSuppression: {
      oneOf: [
        {
          properties: {
            campaignIncludeSuppression: {
              enum: [
                t('pages.targetedEmail.inputValues.yesInputField'),
              ],
            },
            previousTargetedEmailMarketingCoreCid: {
              type: 'string',
              title: `${t('pages.targetedEmail.previousTargetedEmailMarketingCidLabel')}`,
              default: '',
            },
          },
        },
      ],
    },
    // Targeted Email Package ReminderReDrop dependencies
    targetedEmailPackageReminderRedrop: {
      oneOf: [
        {
          properties: {
            targetedEmailPackageReminderRedrop: {
              contains: {
                enum: [t('pages.targetedEmail.inputValues.reminderRedropInputValue')],
              },
            },
            targetedEmailMarketingReminderTitle: {
              type: 'string',
              title: t('pages.targetedEmail.reminder.targetedEmailMarketingReminderTitle'),
            },
            reminderRedropBudget: {
              type: 'string',
              title: t('pages.targetedEmail.reminder.budgetLabel'),
              default: '',
            },
            desiredDeploymentReminderDate: {
              type: 'string',
              title: t('pages.targetedEmail.reminder.desiredDeploymentDateLabel'),
              default: '',
            },
            subjectLineChanging: {
              type: 'string',
              title: t('pages.targetedEmail.reminder.subjectLineChangingLabel'),
              default: '',
              enum: [
                '',
                t('pages.targetedEmail.inputValues.yesInputField'),
                t('pages.targetedEmail.inputValues.noInputField'),
              ],
            },
            preHeaderTextChanging: {
              type: 'string',
              title: t('pages.targetedEmail.reminder.preHeaderTextChangingLabel'),
              default: '',
              enum: [
                '',
                t('pages.targetedEmail.inputValues.yesInputField'),
                t('pages.targetedEmail.inputValues.noInputField'),
              ],
            },
          },
          required: [
            'reminderRedropBudget', 'desiredDeploymentReminderDate',
          ],
        },
      ],
    },
    subjectLineChanging: {
      oneOf: [
        {
          properties: {
            subjectLineChanging: {
              enum: [
                t('pages.targetedEmail.inputValues.yesInputField'),
              ],
            },
            reminderSubjectLine: {
              type: 'string',
              title: t('pages.targetedEmail.reminder.reminderSubjectLine'),
              default: '',
            },
          },
        },
      ],
    },
    preHeaderTextChanging: {
      oneOf: [
        {
          properties: {
            preHeaderTextChanging: {
              enum: [
                t('pages.targetedEmail.inputValues.yesInputField'),
              ],
            },
            reminderPreHeaderText: {
              type: 'string',
              title: t('pages.targetedEmail.reminder.reminderPreHeaderText'),
              default: '',
            },
          },
        },
      ],
    },
    // Targeted Email Package PostalFile dependencies
    targetedEmailPackagePostalFile: {
      oneOf: [
        {
          properties: {
            targetedEmailPackagePostalFile: {
              contains: {
                enum: [t('pages.targetedEmail.inputValues.postalFileInputValue')],
              },
            },
            targetedEmailMarketingPostalFileTitle: {
              type: 'string',
              title: t('pages.targetedEmail.postalFile.TargetedEmailPostalFileTitle'),
            },
            postalFileBudget: {
              type: 'string',
              title: t('pages.targetedEmail.postalFile.budgetLabel'),
              default: '',
              enum: [
                t('pages.targetedEmail.inputValues.postalFileBudget$300'),
                t('pages.targetedEmail.inputValues.postalFileBudget$425'),
                t('pages.targetedEmail.inputValues.postalFileBudget$590'),
                t('pages.targetedEmail.inputValues.postalFileBudget$900'),
                t('pages.targetedEmail.inputValues.postalFileBudget$1200'),
              ],
            },
          },
          required: [
            'postalFileBudget',
          ],
        },
      ],
    },
    // targetedEmailPackageMatchAndFill dependencies
    targetedEmailPackageMatchAndFill: {
      oneOf: [
        {
          properties: {
            targetedEmailPackageMatchAndFill: {
              enum: [t('pages.targetedEmail.inputValues.matchAndFill$200InputValue')],
            },
            targetedEmailMarketingMatchAndFillTitle: {
              type: 'string',
              title: t('pages.targetedEmail.matchAndFill.targetedEmailMarketingMatchAndFillTitle'),
            },
            uploader_csv_file: {
              type: 'string',
              title: t('pages.targetedEmail.matchAndFill.targetedEmailMarketingMatchAndFillTitle'),
              default: '',
            },
            uploaderHelperText: {
              type: 'string',
              title: t('pages.targetedEmail.matchAndFill.csvFileUploaderInformation'),
              enum: [t('pages.targetedEmail.matchAndFill.uploaderHelperText'), true],
            },
          },
        },
        {
          properties: {
            targetedEmailPackageMatchAndFill: {
              enum: [false],
            },
          },
        },
      ],
    },
    // Targeted Email Package Creative dependencies
    targetedEmailPackageCreative: {
      oneOf: [
        {
          properties: {
            targetedEmailPackageCreative: {
              enum: [t('pages.targetedEmail.inputValues.creativeClientProvided$200InputValue')],
            },
            targetedEmailPackageCreativeClientProvidedTitle: {
              type: 'string',
              title: t('pages.targetedEmail.targetedEmailPackageCreativeClientProvided.targetedEmailPackageCreativeClientProvidedTitle'),
            },
            clickThroughUrlCreativeClientProvided: {
              type: 'array',
              title: t('pages.targetedEmail.targetedEmailPackageCreativeClientProvided.clickThroughURL'),
              default: [],
              items: {
                type: 'object',
                properties: {
                  sitelinkUrl: {type: 'string'},
                },
              },
              maximumValue: 6,
            },
            pickUpCreativeFromAPriorCoreOrder: {
              type: 'string',
              title: t('pages.targetedEmail.targetedEmailPackageCreativeClientProvided.pickUpCreativeFromLabel'),
              enum: [
                t('pages.targetedEmail.inputValues.yesInputField'),
                t('pages.targetedEmail.inputValues.noInputField'),
              ],
            },
          },
        },
        {
          properties: {
            targetedEmailPackageCreative: {
              enum: [t('pages.targetedEmail.inputValues.creativeDigitalDesign$250InputValue')],
            },
            targetedEmailPackageCreativeClientDigitalDesignTitle: {
              type: 'string',
              title: t('pages.targetedEmail.targetedEmailPackageCreativeClientDigitalDesign.targetedEmailPackageCreativeClientDigitalDesignTitle'),
            },
            targetedEmailPackageCreativeClientDigitalDesignDescriptionTop: {
              type: 'string',
              title: t('pages.targetedEmail.targetedEmailPackageCreativeClientDigitalDesign.targetedEmailPackageCreativeClientDigitalDesignDescriptionTop'),
            },
            clickThroughUrlCreativeClientDigitalDesign: {
              type: 'array',
              title: t('pages.targetedEmail.targetedEmailPackageCreativeClientDigitalDesign.clickThroughURL'),
              default: [],
              items: {
                type: 'object',
                properties: {
                  sitelinkUrl: { type: 'string' },
                },
              },
              maximumValue: 5,
            },
            templateName: {
              type: 'string',
              title: t('pages.targetedEmail.targetedEmailPackageCreativeClientDigitalDesign.templateNameLabel'),
              description: t('pages.targetedEmail.targetedEmailPackageCreativeClientDigitalDesign.templateNameDescription'),
              default: '',
            },
            targetedEmailPackageCreativeClientDigitalDesignDescriptionBottom: {
              type: 'string',
              title: t('pages.targetedEmail.targetedEmailPackageCreativeClientDigitalDesign.targetedEmailPackageCreativeClientDigitalDesignDescriptionBottom'),
            },
          },
        },
      ],
    },
    pickUpCreativeFromAPriorCoreOrder: {
      oneOf: [
        {
          properties: {
            pickUpCreativeFromAPriorCoreOrder: {
              enum: [t('pages.targetedEmail.inputValues.yesInputField')],
            },
            mCidOfThePreviousCoreOrder: {
              type: 'string',
              title: t('pages.targetedEmail.targetedEmailPackageCreativeClientProvided.pleaseEnterTheMCIDLabel'),
              default: '',
              description: t('pages.targetedEmail.targetedEmailPackageCreativeClientProvided.pleaseEnterTheMCIDDescription'),
            },
          },
          required: [
            'mCidOfThePreviousCoreOrder',
          ],
        },
      ],
    },
    advertiserTier: {
      oneOf: [
        {
          properties: {
            advertiserTier: {
              enum: ['National'],
            },
            creativeDigitalDesign: {
              type: 'array',
              title: '',
              default: '',
              items: {
                type: 'string',
                enum: [
                  t('pages.targetedEmail.creativeDigitalDesign'),
                ],
              },
              uniqueItems: true,
            },
          },
        },
      ],
    },
  },
});

const checkBoxFieldKeys = [
  'targetedEmailPackageCore',
  'targetedEmailPackageReminderRedrop',
  'targetedEmailPackagePostalFile',
  'creativeDigitalDesign'
];

const textFieldKeys = [
  'previousTargetedEmailMarketingCid',
  'versionId',
  'quantityOfEmailsToBeSent',
  'fromLine',
  'subjectLine',
  'reminderSubjectLine',
  'reminderPreHeaderText',
  'preHeaderText',
  'mCidOfThePreviousCoreOrder',
  'templateName',
  'previousTargetedEmailMarketingCoreCid',
];

const decimalInputFieldKeys = [
  'coreBudget',
  'reminderRedropBudget',
];

const headingFieldKeys = [
  'targetedEmailMarketingCoreTitle',
  'targetedEmailMarketingReminderTitle',
  'targetedEmailMarketingPostalFileTitle',
  'targetedEmailMarketingMatchAndFillTitle',
  'targetedEmailPackageCreativeClientProvidedTitle',
  'targetedEmailPackageCreativeClientDigitalDesignTitle',
];

const textAreaFieldKeys = [
  'testSeeds',
  'liveSeeds',
  'notesOrInstructions',
];

const urlFieldKeys = [
  'clickThroughUrl',
];

const dropdownFieldKeys = [
  'campaignIncludeTrackingPixel',
  'campaignIncludeSuppression',
  'subjectLineChanging',
  'preHeaderTextChanging',
  'pickUpCreativeFromAPriorCoreOrder',
  'postalFileBudget',
];

const descriptionFieldKeys = [
  'targetedEmailPackageTitle',
  'targetedEmailPackageCreativeClientDigitalDesignDescriptionTop',
  'targetedEmailPackageCreativeClientDigitalDesignDescriptionBottom',
];

const datePickerFieldKeys = [
  'desiredDeploymentCoreDate',
  'desiredDeploymentReminderDate',
];

const checkBoxFields = checkBoxFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'checkboxes' },
}), {});

const textFields = textFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'textInputField' },
}), {});

const integerFields = decimalInputFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'decimalInputField' },
}), {});

const headingFields = headingFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'headingTextLabel' },
}), {});

const textAreaFields = textAreaFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'TextareaWidget' },
}), {});

const urlFields = urlFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'UrlTextWidget' },
}), {});

const dropdownFields = dropdownFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'dropdown' },
}), {});

const descriptionFields = descriptionFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'textLabel' },
}), {});

const datePickerFields = datePickerFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'datePickerField' },
}), {});

export const TargetedEmailUISchema = {
  'ui:order': ['targetedEmailPackageTitle', 'targetedEmailPackageCore', 'targetedEmailPackageReminderRedrop',
    'targetedEmailPackagePostalFile', 'targetedEmailPackageMatchAndFill', 'targetedEmailPackageCreative', 'creativeDigitalDesign',
    'targetedEmailMarketingCoreTitle', 'coreBudget', 'desiredDeploymentCoreDate', 'versionId', 'quantityOfEmailsToBeSent',
    'fromLine', 'subjectLine', 'preHeaderText', 'testSeeds', 'liveSeeds', 'clickThroughUrl', 'campaignIncludeTrackingPixel',
    'campaignIncludeSuppression', 'previousTargetedEmailMarketingCoreCid', 'targetedEmailMarketingReminderTitle',
    'reminderRedropBudget', 'desiredDeploymentReminderDate', 'subjectLineChanging', 'reminderSubjectLine', 'preHeaderTextChanging',
    'reminderPreHeaderText', 'targetedEmailMarketingPostalFileTitle', 'postalFileBudget', 'targetedEmailMarketingMatchAndFillTitle',
    'uploader_csv_file', 'uploaderHelperText', 'targetedEmailPackageCreativeClientProvidedTitle', 'clickThroughUrlCreativeClientProvided',
    'pickUpCreativeFromAPriorCoreOrder', 'mCidOfThePreviousCoreOrder', 'targetedEmailPackageCreativeClientDigitalDesignTitle',
    'targetedEmailPackageCreativeClientDigitalDesignDescriptionTop', 'clickThroughUrlCreativeClientDigitalDesign', 'templateName',
    'targetedEmailPackageCreativeClientDigitalDesignDescriptionBottom', 'previousTargetedEmailMarketingCid', 'tiedToBrandAmplifier',
    'notesOrInstructions', 'sitelinkUrl', 'advertiserTier'],
  targetedEmailPackageCreative: {
    'ui:widget': (props) => (
        <TargetedEmailCheckbox
            {...props}
        />
    ),
  },
  targetedEmailPackageMatchAndFill: {
    'ui:widget': (props) => (
        <TargetedEmailCheckbox
            {...props}
        />
    ),
  },
  uploader_csv_file: {
    'ui:widget': (props) => (
        <FileUploader
            {...props}
            uploaderType={ProductTypes.TARGETED_EMAIL_UPLOADER}
            totalAllowedFiles={1}
            imageTypes={[]}
            videoTypes={[]}
            documentTypes={['.csv']}
            supportingText=""
        />
    ),
    'ui:style': {
      marginTop: '30px',
      marginBottom: '-10px',
    },
  },
  uploaderHelperText: {
    'ui:widget': 'anchorTextLabel',
    'ui:style': {
      marginBottom: '15px',
    },
  },
  tiedToBrandAmplifier: {
    'ui:style': {
      marginTop: '20px',
    },
  },
  advertiserTier: {
    'ui:style': {
      display: 'none',
    },
  },
  clickThroughUrlCreativeClientProvided: {'ui:widget': AddClickThroughUrl},
  clickThroughUrlCreativeClientDigitalDesign: {'ui:widget': AddClickThroughUrl},
  ...checkBoxFields,
  ...textFields,
  ...integerFields,
  ...headingFields,
  ...textAreaFields,
  ...urlFields,
  ...dropdownFields,
  ...descriptionFields,
  ...datePickerFields,
};

export const TargetedEmailCustomValidate = (formData: any, errors: any, uiSchema: any) => {
  if (formData?.versionId === '') {
    errors.versionId.addError('This field is required');
    errors.addError(true);
  }

  if (formData?.quantityOfEmailsToBeSent === '') {
    errors.quantityOfEmailsToBeSent.addError('This field is required');
    errors.addError(true);
  }

  if (formData?.fromLine === '') {
    errors.fromLine.addError('This field is required');
    errors.addError(true);
  }

  if (formData?.subjectLine === '') {
    errors.subjectLine.addError('This field is required');
    errors.addError(true);
  }

  if (formData?.preHeaderText === '') {
    errors.preHeaderText.addError('This field is required');
    errors.addError(true);
  }

  if (formData?.testSeeds === '') {
    errors.testSeeds.addError('This field is required');
    errors.addError(true);
  }

  if (formData?.liveSeeds === '') {
    errors.liveSeeds.addError('This field is required');
    errors.addError(true);
  }

  if (formData?.clickThroughUrl === '') {
    errors.clickThroughUrl.addError('This field is required');
    errors.addError(true);
  }

  if (formData?.reminderRedropBudget === '') {
    errors.reminderRedropBudget.addError('This field is required');
    errors.addError(true);
  }

  if (formData?.postalFileBudget === '') {
    errors.postalFileBudget.addError('This field is required');
    errors.addError(true);
  }

  if (formData?.mCidOfThePreviousCoreOrder === '') {
    errors.mCidOfThePreviousCoreOrder.addError('This field is required');
    errors.addError(true);
  }

  if (
      formData.targetedEmailPackageCore.length === 0 &&
      formData?.targetedEmailPackageReminderRedrop.length === 0 &&
      formData.targetedEmailPackagePostalFile.length === 0 &&
      formData?.targetedEmailPackageMatchAndFill === undefined &&
      formData?.targetedEmailPackageCreative === undefined &&
      (
          formData.advertiserTier !== "National" ||
          (formData.advertiserTier === "National" && formData?.creativeDigitalDesign.length === 0)
      )
  ) {
    errors.targetedEmailPackageCore.addError(t('pages.targetedEmail.checkBoxErrorMessage'));
    errors.addError(true);
  }
  return errors;
};
