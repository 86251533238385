import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import {
  Button,
  Container,
  Divider,
  Grid, IconButton, Modal
} from '@mui/material';
import { CoButtonBack } from 'components/buttons/CoButtonBack';
import { CoButtonContinue } from 'components/buttons/CoButtonContinue';
import { useDispatch, useSelector } from 'react-redux';
import { selectGenericStepperActiveStep, setActiveStep } from 'services/genericForm';
import { useQuery } from 'pages/themes/details/ThemeDetails';
import { useSearchParams } from 'react-router-dom';
import { useTopnavMenu } from 'hooks/useTopnavMenu';
import { Urls } from 'navigation/Urls';
import { useNavigationHandler } from 'hooks/useNavigationHandler';
import { FormComponent } from '../DetailsPageForm';
import * as React from "react";
import S1 from "components/labels/S1";
import CloseIcon from "@mui/icons-material/Close";
import { IQTheme, IQThemeV2 } from "@gannettdigital/shared-react-components";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";

export default function GenericStepper(props: any) {
  const { steps } = props;
  const dispatch = useDispatch();
  const navigate = useNavigationHandler();
  const query = useQuery();
  const [searchParams, setSearchParams] = useSearchParams();
  const topnav = useTopnavMenu(Urls.GenericProductDetails);
  const activeStep = useSelector(selectGenericStepperActiveStep);
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  useEffect(() => {
    setIsDirty(props?.isDirty || false);
  }, [props?.isDirty]);

  const handleBack = () => {
    if (isDirty) {
      setIsModalOpen(true);
      return;
    }
    if (activeStep === 0) {
      navigate.to(topnav.previousStep());
      return;
    }
    const newStep = activeStep - 1;
    setSearchParams({step: newStep.toString()});
    dispatch(setActiveStep(newStep));
  };

  const handleLeave = () => {
    setIsModalOpen(false);
    if (activeStep === 0) {
      navigate.to(topnav.previousStep());
      return;
    }
    const newStep = activeStep - 1;
    setSearchParams({step: newStep.toString()});
    dispatch(setActiveStep(newStep));
  };

  const handleStay = () => {
    setIsModalOpen(false);
  };

  const updateQueryParams = () => {
    const params: any = {};
    if (searchParams.get('step')) params.step = searchParams.get('step');
    setSearchParams(params);
  };

  const handleNext = () => {
    const newStep = activeStep + 1;
    setSearchParams({step: newStep});
    dispatch(setActiveStep(newStep));
  };

  useEffect(() => {
    const step = query.get('step');
    if (step) dispatch(setActiveStep(Number(step)));
    updateQueryParams();
  }, [query]);

  useEffect(() => {
    props.setHandleNext(() => handleNext);
  }, [query, activeStep]);

  useEffect(() => {
    if (activeStep === steps.length) {
      dispatch(setActiveStep(0));
      props.onComplete();
    }
  }, [activeStep]);

  const renderStepPreview = (activeStep, index) => ((activeStep === 0 && index < activeStep + 3)
      || (index > (activeStep - 2) && index < (activeStep + 2))
      || (activeStep === steps.length - 1 && index > activeStep - 3));

  return (
      <Container
          sx={{
            maxWidth: '100% !important',
            padding: 2,
          }}
      >
        <Stepper activeStep={activeStep} sx={{width: '100%'}}>
          {steps.map((step, index) => {
            const stepProps: { completed?: boolean } = {};
            const labelProps: {
              optional?: React.ReactNode;
            } = {};
            return renderStepPreview(activeStep, index) ? (
                <Step key={step.title} {...stepProps}>
                  <StepLabel {...labelProps}>{step.title}</StepLabel>
                </Step>
            ) : (
                <Box/>
            );
          })}
        </Stepper>
        {activeStep !== steps.length && (
            <FormComponent
                currentSchema={steps[activeStep].schema}
                product={props.product}
                onSubmitEvent={props.onSubmitEvent}
                currentUISchema={steps[activeStep].uiSchema}
                customValidate={steps[activeStep].customValidate}
                onBack={props.onBack}
                onValid={props.onValid}
                setProduct={props.setProduct}
                isStepper
            >
              <Grid item xs={12}>
                <Divider sx={{margin: '100px 0 40px !important'}}/>
              </Grid>
              <Grid item xs={12} sx={{paddingBottom: '117px'}}>
                <Box>
                  <CoButtonBack
                      label="Back"
                      onClick={handleBack}
                      sx={{float: 'left', minWidth: 'fit-content'}}
                  />
                  <CoButtonContinue
                      label={props?.product?.enableLocalServiceAds === "Yes" ? props.continueButtonLabel : (activeStep === steps.length - 1) ? 'Finish' : props.continueButtonLabel}
                      disabled={props.hasErrors}
                      sx={{float: 'right', minWidth: 'fit-content'}}
                      id="continueButton"
                      type="submit"
                  />
                </Box>
              </Grid>
            </FormComponent>
        )}
        {/* Modal To Show Pop-Up */}
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={isModalOpen}
            onClose={handleStay}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
              style: {backgroundColor: IQTheme.palette.common.black, opacity: '0.86'},
            }}
        >
          <Fade in={isModalOpen}>
            <Grid container sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 556,
              bgcolor: 'background.paper',
              border: '1px',
              borderStyle: 'solid',
              borderColor: IQTheme.palette.info.main,
              borderRadius: '3px',
              boxShadow: 24,
              p: 4,
              padding: '0 8px 8px 24px',
            }}>
              <Grid item xs={12} textAlign="end">
                <IconButton aria-label="close" onClick={handleStay} sx={{paddingRight: '0'}}>
                  <CloseIcon sx={{color: IQThemeV2.palette.text.primary, fontSize: '32px'}}/>
                </IconButton>
              </Grid>
              <Grid item xs={12}>
                <S1 sx={{
                  fontSize: '18px',
                  fontWeight: 700,
                  lineHeight: '27px',
                  color: '#03334E',
                  marginTop: '0',
                }}
                >
                  <strong>Leave page?</strong>
                </S1>
              </Grid>
              <Grid item xs={12} sx={{marginTop: '13px'}}>
                <S1 sx={{fontSize: '16px', fontWeight: 400, lineHeight: '24px'}}>
                  Are you sure you want to leave this page? The information you have entered may not
                  be saved.
                </S1>
              </Grid>
              <Grid item xs={12} sx={{textAlign: 'right', marginTop: '27px'}}>
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={handleStay}
                    sx={{minWidth: '86px !important'}}
                >
                  Stay on page
                </Button>
                <Button
                    className='co-modal-confirm-confirm-button'
                    variant="contained"
                    color="primary"
                    onClick={handleLeave}
                    style={{marginLeft: '10px'}}
                >
                  Leave page
                </Button>
              </Grid>
            </Grid>
          </Fade>
        </Modal>
      </Container>
  );
}
