/* eslint-disable max-len */
/* eslint-disable import/no-extraneous-dependencies */
import Form from '@rjsf/mui';
import validator from '@rjsf/validator-ajv8';
import {
  FormContextType,
  RegistryWidgetsType,
  RJSFSchema,
  RJSFValidationError,
  StrictRJSFSchema,
} from '@rjsf/utils';
import {
  createRef,
  useContext, useEffect, useMemo, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router';
import { selectOrderFlow, selectOrderItems, selectOrdersContent } from 'services/ordersSlice';
import {
  Box, Divider, Grid, Typography,
} from '@mui/material';
import { CoButtonBack } from 'components/buttons/CoButtonBack';
import { CoButtonContinue } from 'components/buttons/CoButtonContinue';
import { putProduct } from 'services/productsSlice';
import { useTopnavMenu } from 'hooks/useTopnavMenu';
import { Urls } from 'navigation/Urls';
import { useNavigationHandler } from 'hooks/useNavigationHandler';
import CoNavigationConfirm from 'components/navigation/CoNavigationConfirm';
import CoModalConfirm from 'components/modals/CoModalConfirm';
import { SaveAndCloseContext } from 'context/SaveAndCloseContext';
import { IChangeEvent } from '@rjsf/core';
import UrlTextField from 'pages/generic-product/details/custom-fields/UrlTextField';
import TextInputField from 'pages/generic-product/details/custom-fields/TextInputField';
import AnchorTextLabel from 'pages/generic-product/details/custom-fields/AnchorTextLabel';
import TextLabel from 'pages/generic-product/details/custom-fields/TextLabel';
import HeadingTextLabel from 'pages/generic-product/details/custom-fields/HeadingTextLabel';
import EmailTextField from 'pages/generic-product/details/custom-fields/EmailTextField';
import PhoneNumberTextField from 'pages/generic-product/details/custom-fields/PhoneNumberTextField';
import SchedulerWidget from 'pages/generic-product/details/custom-fields/SchedulerWidget';
import RadioButtonWithTooltip
  from 'pages/generic-product/details/custom-fields/RadioButtonWithTooltip';
import AddressField from 'pages/generic-product/details/custom-fields/AddressField';
import DatePickerField from 'pages/generic-product/details/custom-fields/DatePickerField';
import IntegerInputField from 'pages/generic-product/details/custom-fields/IntegerInputField';
import CheckBoxField from 'pages/generic-product/details/custom-fields/checkBoxField';
import { OrderFlow } from 'shared/constants';
import RadioButton from 'pages/generic-product/details/custom-fields/RadioButton';
import DecimalInputField from 'pages/generic-product/details/custom-fields/DecimalInputField';
import TimePickerField from 'pages/generic-product/details/custom-fields/TimePickerField';
import Dropdown from './custom-fields/Dropdown';
import TextField from './custom-fields/TextField';
import GenericStepper from './generic-stepper/GenericStepper';
import { getOrderItemSchema } from './utils/functions';
import { ObjectFieldTemplate } from './ui-schema/UISchema';
import { selectOpportunity } from "services/cefSlice";

interface Props {
  product: any
  businessData?: any
}

const CustomForm = Form as any;

const customWidgets: RegistryWidgetsType = {
  TextareaWidget: TextField,
  UrlTextWidget: UrlTextField,
  radioButton: RadioButton,
  dropdown: Dropdown,
  textInputField: TextInputField,
  anchorTextLabel: AnchorTextLabel,
  textLabel: TextLabel,
  headingTextLabel: HeadingTextLabel,
  phoneNumberTextField: PhoneNumberTextField,
  EmailTextWidget: EmailTextField,
  schedulerWidget: SchedulerWidget,
  radioButtonWithTooltip: RadioButtonWithTooltip,
  addressField: AddressField,
  datePickerField: DatePickerField,
  integerInputField: IntegerInputField,
  decimalInputField: DecimalInputField,
  checkBoxField: CheckBoxField,
  timePickerField: TimePickerField,
};

export function FormComponent<T = any, S extends StrictRJSFSchema = RJSFSchema, F extends FormContextType = any>(props: any) {
  const [formData, setFormData] = useState<any>({});
  const formRef = createRef() as any;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const getValidErrors = (errors: RJSFValidationError[] | undefined, fields: string[]): RJSFValidationError[] | undefined => {
    // For the case of a single input form
    if (fields.length === 0 && typeof errors !== 'object') {
      return errors;
    }
    const newErrors: RJSFValidationError[] = [];
    errors.forEach((obj) => {
      if (fields.includes(obj.property.replace('.', ''))) newErrors.push(obj);
    });
    return newErrors;
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onSubmit = (data: IChangeEvent<T, S, F>, id?: string) => {
    if (props.onSubmitEvent) props.onSubmitEvent({ formData: data.formData });
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onChange = (data: IChangeEvent<T, S, F>, id?: string) => {
    setFormData(data.formData);
    const newErrors = getValidErrors(data.errors, Object.keys(data.formData));
    const hasErrors = newErrors.length > 0;
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    props.onValid && props.onValid(!hasErrors);
    if (props?.isStepper) {
      props.setProduct(prev => ({ ...prev, ...data.formData }));
    } else props.setProduct(data.formData);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onError = (errors: RJSFValidationError[]) => {
    const newErrors = getValidErrors(errors, Object.keys(formData));
    const hasErrors = newErrors.length > 0;
    if (props.onValid) props.onValid(!hasErrors);
  };

  return (
    <CustomForm ref={formRef} schema={props.currentSchema}
      validator={validator} formData={props.product}
      onSubmit={onSubmit} templates={{ ObjectFieldTemplate }}
      uiSchema={props.currentUISchema}
      customValidate={props.customValidate} liveValidate liveOmit
      showErrorList={false} noValidate={false} omitExtraData
      widgets={customWidgets} onChange={onChange} onError={onError}
    >
      {props.children}
    </CustomForm>
  );
}

export default function DetailsPageForm(props: Props) {
  const { orderItemId } = useParams();
  const navigate = useNavigationHandler();
  const dispatch = useDispatch();
  const history: any = useLocation();
  const saveAndCloseContext = useContext(SaveAndCloseContext);
  const topNav = useTopnavMenu(Urls.GenericProductDetails);
  const orderItems = useSelector(selectOrderItems);
  const [currentSchema, setCurrentSchema] = useState(null);
  const [currentUISchema, setCurrentUISchema] = useState(null);
  const [handleNext, setHandleNext] = useState(null);
  const [product, setProduct] = useState(props.product);
  const [isDirty, setIsDirty] = useState(false);
  const [hasErrors, setHasErrors] = useState(false);
  const [showContinueModal, setShowContinueModal] = useState(false);
  const { orderFlow: orderDataFlow } = useSelector(selectOrdersContent);
  const orderFlow = useSelector(selectOrderFlow);
  const opportunity = useSelector(selectOpportunity);

  const salesforceValues = {
    isOrange: (orderFlow === OrderFlow.SALES_FORCE || orderDataFlow === OrderFlow.SALES_FORCE),
    isBlue: (orderFlow === OrderFlow.PUNCHLIST || orderDataFlow === OrderFlow.PUNCHLIST),
  };

  const isRedirectedFromOverviewPage = useMemo(() => history.state?.previousPath.includes('overview'), [history]);
  const continueButtonLabel = isRedirectedFromOverviewPage ? 'Save & Return' : 'Continue';
  const backButtonLabel = isRedirectedFromOverviewPage ? 'Cancel' : 'Back';

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const customValidateDefault = (formData: any, errors, uiSchema) => errors;
  const [customValidate, setCustomValidate] = useState(customValidateDefault);

  useEffect(() => {
    if (orderItems.content.length > 0) {
      const orderItem = orderItems.content.filter(item => item.id.toString() === orderItemId);
      if (orderItem.length > 0) {
        getOrderItemSchema(orderItem[0], setCurrentSchema, setCurrentUISchema, setCustomValidate, opportunity, { ...props.businessData, ...salesforceValues, ...product });
      }
    }
  }, [orderItems.content]);

  useEffect(() => {
    const prevData = props?.product;
    const changedData = product;
    let isDirty = false;

    Object.keys(changedData).some((key: any) => {
      const prevValue = prevData[key];
      const newValue = changedData[key];

      if (key.includes('uploader_')) {
        if (prevValue && JSON.stringify(prevValue) !== newValue) {
          isDirty = true;
        } else if (newValue !== '' && JSON.parse(newValue)?.length > 0) {
          isDirty = true;
        }
      } else if (key in prevData) {
        if (Array.isArray(prevValue)) {
          if (JSON.stringify(prevValue) !== JSON.stringify(newValue)) {
            isDirty = true;
          }
        } else if (prevValue !== newValue) {
          isDirty = true;
        }
      } else if (typeof newValue === 'boolean' && newValue === false) {
        isDirty = false;
      } else if (newValue?.length !== 0) {
        isDirty = true;
      }
      if (isDirty) {
        return true;
      }
    });
    setIsDirty(isDirty);
  }, [product]);

  const onBack = async () => {
    if (isRedirectedFromOverviewPage) navigate.to(Urls.Overview);
    else navigate.to(topNav.previousStep());
  };

  const onComplete = () => {
    if (!isRedirectedFromOverviewPage) navigate.to(topNav.nextStep());
    else navigate.to(Urls.Overview);
  };

  const saveData = async (formData, shouldFlush: boolean = false) => {
    const content = { ...formData };

    Object.keys(content).forEach((key: string) => {
      if (key.includes('uploader_')) {
        content[key] = JSON.parse(content[key]);
      }
    });

    await dispatch(putProduct({
      orderItemId,
      content,
      flush: shouldFlush,
    }));
  };

  const onValidEvent = (isValid) => {
    setHasErrors(!isValid);
  };

  const onSubmitEvent = async ({ formData }) => {
    if (isRedirectedFromOverviewPage) {
      setShowContinueModal(true);
      setProduct(formData);
    } else {
      await saveData(formData);
      if (handleNext) {
        handleNext();
      } else navigate.to(topNav.nextStep());
    }
  };

  const confirmSaveClose = () => {
    saveData(product, true);
    setShowContinueModal(false);
    navigate.to(Urls.Overview);
  };

  useEffect(() => {
    saveAndCloseContext.setSaveAndCloseAction(() => {
      navigate.clearDefaultNavigation();
      saveData(product);
    });
  }, [product]);

  const confirmModalDescription = (
    <div>
      <Typography marginBottom={2}>Are you sure you want to save these changes?</Typography>
      <Typography>
        <b>Note: </b>
        Changes may impact the accuracy of the order so please review the order overview before
        submitting.
      </Typography>
    </div>
  );

  return currentSchema && (
    <Box sx={{ '& .MuiList-root': { display: 'none' } }}>
      {!Array.isArray(currentSchema) ? (
        <FormComponent
          currentSchema={currentSchema}
          product={product}
          onSubmitEvent={onSubmitEvent}
          currentUISchema={currentUISchema}
          customValidate={customValidate}
          onBack={onBack}
          onValid={onValidEvent}
          setProduct={setProduct}
        >
          <Grid item xs={12}>
            <Divider sx={{ margin: '100px 0 40px !important' }} />
          </Grid>
          <Grid item xs={12} sx={{ paddingBottom: '117px' }}>
            <Box>
              <CoButtonBack
                label={backButtonLabel}
                onClick={() => onBack()}
                disabled={false}
                sx={{ float: 'left', minWidth: 'fit-content' }}
              />
              <CoButtonContinue
                label={continueButtonLabel}
                disabled={hasErrors}
                sx={{ float: 'right', minWidth: 'fit-content' }}
                id="continueButton"
                type="submit"
              />
            </Box>
          </Grid>
        </FormComponent>
      ) : (
        <Grid item xs={12} pt="48px">
          <GenericStepper
            steps={currentSchema}
            currentSchema={currentSchema}
            onSubmitEvent={onSubmitEvent}
            currentUISchema={currentUISchema}
            customValidate={customValidate}
            onBack={onBack}
            onComplete={onComplete}
            setHandleNext={setHandleNext}
            product={product}
            onValid={onValidEvent}
            hasErrors={hasErrors}
            continueButtonLabel={continueButtonLabel}
            setProduct={setProduct}
            isDirty={isDirty}
          />
        </Grid>
      )}
      <CoNavigationConfirm
        showDialog={isDirty}
        leavePageCopy
      />
      <CoModalConfirm
        open={showContinueModal}
        title="Save Changes"
        description={confirmModalDescription}
        confirmText="Save"
        cancelText="Back"
        handleClose={() => setShowContinueModal(false)}
        handleConfirm={confirmSaveClose}
      />
    </Box>
  );
}
