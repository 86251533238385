/* eslint-disable no-param-reassign */
import { createAction, createReducer } from '@reduxjs/toolkit';
import { StatusType } from 'models/StatusType';
import { RootState } from './redux/store';
import { createAsyncThunkCatchErrors } from './errorSlice';
import AxiosAPI from './common/axios';

export interface StatusChange {
  id: number
  status: string
  changedBy: string
  dateChanged: Date
}

export interface ThemeFamily {
  id: number
  name: string
}

export interface ThemeSelectionItem {
  themeId: number
  label: string
}

export interface ThemeFeature {
  id: number
  title: string
  description: string
  iconFileName: string | null
  themeFeatureTierId?: number | null
}

export interface ThemeBenefit {
  id: number
  title: string
  description: string
}

export interface MarketCategory {
  id: number
  name: string
  notes?: string
  active: boolean
  themeCount: number
}

export interface BusinessFunctionality {
  id: number
  name: string
  description?: string
  notes?: string
  active: boolean
  slug?: string
  themeCount?: number
}

export interface Product {
  id: number
  name: string
  productName?: string
}

export interface CapacityStatus {
  id: number
  productId?: number
  product: string
  updatedAt: Date
  days: number
  weeks: number
  remarks?: string
}

export interface BundleItem {
  id: number
  bundleId: number
  themeId: number
  addon: boolean
}

export interface BundleListView {
  id: number
  name: string
  mainThemeId: number
  mainThemeName: string
  addedProducts: string
  addedAddOns: string
  active: boolean
}

export interface Bundle {
  id: number
  name: string
  mainThemeId: number
  active: boolean
}

export interface Country {
  id: number
  name: string
  alpha2Code: string
  alpha3Code: string
  numericCode: number
  sourceId?: string
}

export interface AdminState {
  maintenanceMode: StatusType<'ON' | 'OFF'>
  changeLog: StatusType<StatusChange[]>
  themeFamilies: StatusType<ThemeFamily[]>
  currentThemeFamily: StatusType<ThemeFamily>
  themeFeatures: StatusType<ThemeFeature[]>
  currentThemeFeature: StatusType<ThemeFeature>
  themeBenefits: StatusType<ThemeBenefit[]>
  currentThemeBenefit: StatusType<ThemeBenefit>
  marketCategories: StatusType<MarketCategory[]>
  currentMarketCategory: StatusType<MarketCategory>
  businessFunctionalities: StatusType<BusinessFunctionality[]>
  currentBusinessFunctionality: StatusType<BusinessFunctionality>
  countries: StatusType<Country[]>
  currentCountries: StatusType<Country[]>
  products: StatusType<Product[]>
  capacityStatuses: StatusType<CapacityStatus[]>
  bundles: StatusType<BundleListView[]>
  currentBundle: StatusType<Bundle>
  currentBundleProducts: StatusType<ThemeSelectionItem[]>
  currentBundleAddOns: StatusType<ThemeSelectionItem[]>
  allGoldThemes: StatusType<ThemeSelectionItem[]>
  allAddOns: StatusType<ThemeSelectionItem[]>
  allThemes: StatusType<ThemeSelectionItem[]>
}

export const allProducts: Product[] = [
  {
    id: 1,
    name: 'website - standard',
  },
  {
    id: 2,
    name: 'website - gold',
  },
  {
    id: 3,
    name: 'website - custom',
  },
  {
    id: 4,
    name: 'website - landing page',
  },
  {
    id: 5,
    name: 'website - custom landing page',
  },
  {
    id: 6,
    name: 'website - lite',
  },
  {
    id: 7,
    name: 'e-commerce microsite',
  },
  {
    id: 8,
    name: 'premium listing',
  },
  {
    id: 9,
    name: 'chat',
  },
  {
    id: 10,
    name: 'chat - platinum',
  },
  {
    id: 11,
    name: 'add-ons',
  },
  {
    id: 13,
    name: 'master addons',
  },
  {
    id: 14,
    name: 'seo-standard',
  },
  {
    id: 15,
    name: 'seo-custom',
  },
  {
    id: 16,
    name: 'seo-blog-standard',
  },
  {
    id: 17,
    name: 'seo-blog-enhanced',
  },
  {
    id: 18,
    name: 'smm-standard',
  },
  {
    id: 19,
    name: 'smm-custom',
  },
  {
    id: 20,
    name: 'dmsnext-cc-by-dash',
  },
  {
    id: 21,
    name: 'dmsnext-cc',
  },
  {
    id: 22,
    name: 'seo-topic-location',
  },
  {
    id: 23,
    name: 'seo-content-pages',
  },
  {
    id: 24,
    name: 'smm-facebook-posts',
  },
  {
    id: 25,
    name: 'smm-infographics',
  },
  {
    id: 26,
    name: 'smm-x',
  },
  {
    id: 27,
    name: 'smm-instagram',
  },
  {
    id: 28,
    name: 'smm-pinterest',
  },
  {
    id: 29,
    name: 'custom-reporting',
  },
  {
    id: 30,
    name: 'custom-solutions-spotify',
  },
  {
    id: 31,
    name: 'custom-solutions-snapchat',
  },
  {
    id: 32,
    name: 'custom-solutions-pandora',
  },
  {
    id: 33,
    name: 'smb-retail-gci-only-smart-listings',
  },
  {
    id: 34,
    name: 'smb-retail-gci-only-visible-presence',
  },
  {
    id: 35,
    name: 'custom-solutions-linkedin',
  },
  {
    id: 36,
    name: 'social-ads-lead-ads-with-detailed-targeting',
  },
  {
    id: 37,
    name: 'search-engine-marketing',
  },
  {
    id: 38,
    name: 'social-ads-website-clicks',
  },
  {
    id: 39,
    name: 'social-ads-website-clicks-with-detailed-targeting',
  },
  {
    id: 40,
    name: 'premium-listings-ten-plus-physician-directories',
  },
  {
    id: 41,
    name: 'premium-listings-10-plus',
  },
  {
    id: 42,
    name: 'get-creative-service-fee',
  },{
    id: 43,
    name: 'custom-solutions-twitter',
  },
  {
    id: 44,
    name: 'xmo',
  },
  {
    id: 45,
    name: 'aus-tiktok',
  },
  {
    id: 46,
    name: 'aus-total-track',
  },
  {
    id: 47,
    name: 'aus-custom-facebook',
  },
  {
    id: 48,
    name: 'aus-linkedin',
  },
  {
    id: 49,
    name: 'aus-performance-max',
  },
  {
    id: 50,
    name: 'aus-youtube',
  },
  {
    id: 51,
    name: 'aus-live-chat',
  },
  {
    id: 52,
    name: 'multi-media-creatives',
  },
  {
    id: 53,
    name: 'aus-demand-gen',
  },
  {
    id: 54,
    name: 'aus-site',
  },
  {
    id: 55,
    name: 'aus-video-creative-service',
  },
  {
    id: 56,
    name: 'aus-google-display-network',
  },
  {
    id: 57,
    name: 'aus-premium-listings-1-9',
  },
  {
    id: 58,
    name: 'aus-social-catalogue',
  },
  {
    id: 59,
    name: 'aus-xmo-social-upgrade',
  },
  {
    id: 60,
    name: 'aus-campaign-landing-pages',
  },
  {
    id: 61,
    name: 'aus-social-fb-conversions',
  },
];

const initialState: AdminState = {
  maintenanceMode: {
    status: 'idle',
    message: '',
    content: 'OFF',
  },
  changeLog: {
    status: 'idle',
    message: '',
    content: [],
  },
  themeFamilies: {
    status: 'idle',
    message: '',
    content: [],
  },
  currentThemeFamily: {
    status: 'idle',
    message: '',
    content: { id: 0, name: '' },
  },
  themeFeatures: {
    status: 'idle',
    message: '',
    content: [],
  },
  currentThemeFeature: {
    status: 'idle',
    message: '',
    content: {
      id: 0, title: '', description: '', iconFileName: null,
    },
  },
  themeBenefits: {
    status: 'idle',
    message: '',
    content: [],
  },
  currentThemeBenefit: {
    status: 'idle',
    message: '',
    content: { id: 0, title: '', description: '' },
  },
  marketCategories: {
    status: 'idle',
    message: '',
    content: [],
  },
  currentMarketCategory: {
    status: 'idle',
    message: '',
    content: {
      id: 0, name: '', active: false, themeCount: 0,
    },
  },
  businessFunctionalities: {
    status: 'idle',
    message: '',
    content: [],
  },
  currentBusinessFunctionality: {
    status: 'idle',
    message: '',
    content: {
      id: 0, name: '', active: false, themeCount: 0,
    },
  },
  countries: {
    status: 'idle',
    message: '',
    content: [],
  },
  currentCountries: {
    status: 'idle',
    message: '',
    content: [],
  },
  products: {
    status: 'idle',
    message: '',
    content: allProducts,
  },
  capacityStatuses: {
    status: 'idle',
    message: '',
    content: [],
  },
  bundles: {
    status: 'idle',
    message: '',
    content: [],
  },
  currentBundle: {
    status: 'idle',
    message: '',
    content: {
      id: 0, name: '', mainThemeId: 0, active: false,
    },
  },
  currentBundleAddOns: {
    status: 'idle',
    message: '',
    content: [],
  },
  currentBundleProducts: {
    status: 'idle',
    message: '',
    content: [],
  },
  allAddOns: {
    status: 'idle',
    message: '',
    content: [],
  },
  allGoldThemes: {
    status: 'idle',
    message: '',
    content: [],
  },
  allThemes: {
    status: 'idle',
    message: '',
    content: [],
  },
};

/** theme Features --> */
export const getThemeFeatures = createAsyncThunkCatchErrors(
  'admin/features',
  async () => (await AxiosAPI.get('/themefeatures')).data,
);

export const getThemeFeatureById = createAsyncThunkCatchErrors(
  'admin/themeFeature/get',
  async (id: string) => (await AxiosAPI.get(`/themefeatures/${id}`)).data,
);

export const updateThemeFeature = createAsyncThunkCatchErrors(
  'admin/themeFeature/update',
  async (resource) => (await AxiosAPI.put('/themefeatures', resource)).data,
);

export const deleteThemeFeature = createAsyncThunkCatchErrors(
  'admin/themeFeature/delete',
  async (id) => {
    await AxiosAPI.delete(`/themefeatures/${id}`);
    return id;
  },
);

export const createThemeFeature = createAsyncThunkCatchErrors(
  'admin/themeFeature/create',
  async (resource) => (await AxiosAPI.post('/themefeatures', resource)).data,
);

/** */

/** theme benefits --> */
export const getThemeBenefits = createAsyncThunkCatchErrors(
  'admin/themeBenefits/list',
  async () => (await AxiosAPI.get('/themebenefits')).data,
);

export const getThemeBenefitById = createAsyncThunkCatchErrors(
  'admin/themeBenefit/get',
  async (id: string) => (await AxiosAPI.get(`/themebenefits/${id}`)).data,
);

export const updateThemeBenefit = createAsyncThunkCatchErrors(
  'admin/themeBenefit/update',
  async (resource) => (await AxiosAPI.put('/themebenefits', resource)).data,
);

export const deleteThemeBenefit = createAsyncThunkCatchErrors(
  'admin/themeBenefit/delete',
  async (id) => {
    await AxiosAPI.delete(`/themebenefits/${id}`);
    return id;
  },
);

export const createThemeBenefit = createAsyncThunkCatchErrors(
  'admin/themeBenefit/create',
  async (resource) => (await AxiosAPI.post('/themebenefits', resource)).data,
);

/** */

/** theme families --> */
export const getThemeFamilies = createAsyncThunkCatchErrors(
  'admin/families',
  async () => (await AxiosAPI.get('/theme-families?size=1000')).data,
);

export const getThemeFamilyById = createAsyncThunkCatchErrors(
  'admin/families/get',
  async (id: string) => (await AxiosAPI.get(`/theme-families/${id}`)).data,
);

export const updateThemeFamily = createAsyncThunkCatchErrors(
  'admin/themeFamiliy/update',
  async (resource) => (await AxiosAPI.put('/theme-families', resource)).data,
);

export const deleteThemeFamily = createAsyncThunkCatchErrors(
  'admin/themeFamiliy/delete',
  async (themeId) => {
    await AxiosAPI.delete(`/theme-families/${themeId}`);
    return themeId;
  },
);

export const createThemeFamily = createAsyncThunkCatchErrors(
  'admin/themeFamiliy/create',
  async (resource) => (await AxiosAPI.post('/theme-families', resource)).data,
);
/** <-- theme families */

/** market categories --> */
export const getMarketCategories = createAsyncThunkCatchErrors(
  'admin/market-categories/list',
  async () => (await AxiosAPI.get('/market-categories?size=1000')).data,
);

export const getMarketCategoryById = createAsyncThunkCatchErrors(
  'admin/market-categories/get',
  async (id: string) => (await AxiosAPI.get(`/market-categories/${id}`)).data,
);

export const updateMarketCategory = createAsyncThunkCatchErrors(
  'admin/market-categories/update',
  async (resource) => (await AxiosAPI.put('/market-categories', resource)).data,
);

export const deleteMarketCategory = createAsyncThunkCatchErrors(
  'admin/market-categories/delete',
  async (themeId) => {
    await AxiosAPI.delete(`/market-categories/${themeId}`);
    return themeId;
  },
);

export const createMarketCategory = createAsyncThunkCatchErrors(
  'admin/market-categories/create',
  async (resource) => (await AxiosAPI.post('/market-categories', resource)).data,
);
/** <-- market categories */

/** business functionality --> */
export const getBusinessFunctionalities = createAsyncThunkCatchErrors(
  'admin/businessFunctionalities/list',
  async () => (await AxiosAPI.get('/business/functionalities?size=1000')).data,
);

export const getBusinessFunctionalityById = createAsyncThunkCatchErrors(
  'admin/businessFunctionality/get',
  async (id: string) => (await AxiosAPI.get(`/business/functionalities/${id}`)).data,
);

export const updateBusinessFunctionality = createAsyncThunkCatchErrors(
  'admin/businessFunctionality/update',
  async (resource) => (await AxiosAPI.put('/business/functionalities', resource)).data,
);

export const createBusinessFunctionality = createAsyncThunkCatchErrors(
  'admin/businessFunctionality/create',
  async (resource) => (await AxiosAPI.post('/business/functionalities', resource)).data,
);

export const deleteBusinessFunctionality = createAsyncThunkCatchErrors(
  'admin/businessFunctionality/delete',
  async (id) => {
    await AxiosAPI.delete(`/business/functionalities/${id}`);
    return id;
  },
);
/** <-- business functionality */

/** bundles list --> */
export const getBundles = createAsyncThunkCatchErrors(
  'admin/bundles/list',
  async () => (await AxiosAPI.get('/bundles?size=1000')).data,
);

export const getBundleById = createAsyncThunkCatchErrors(
  'admin/bundles/get',
  async (id: string) => (await AxiosAPI.get(`/bundles/${id}`)).data,
);

export const deleteBundle = createAsyncThunkCatchErrors(
  'admin/bundles/delete',
  async (themeId) => {
    await AxiosAPI.delete(`/bundles/${themeId}`);
    return themeId;
  },
);

export const createBundle = createAsyncThunkCatchErrors(
  'admin/bundles/create',
  async (resource) => (await AxiosAPI.post('/bundles', resource)).data,
);

export const updateBundleItems = createAsyncThunkCatchErrors(
  'admin/bundleItems/update',
  async ({ bundleId, resource }) => (await AxiosAPI.post(`/bundles/${bundleId}/items`, resource)).data,
);

export const getBundleItems = createAsyncThunkCatchErrors(
  'admin/bundleItems/get',
  async (bundleId) => (await AxiosAPI.get(`/bundles/${bundleId}/items`)).data,
);

export const updateBundle = createAsyncThunkCatchErrors(
  'admin/bundles/update',
  async ({ bundle }) => (await AxiosAPI.put('/bundles', bundle)).data,
);

/** update campaignId  */

export const updateCampaignId = createAsyncThunkCatchErrors(
  'admin/manage_cid/update',
  async (resource) => (await AxiosAPI.put('/orders/items/campaigns', resource)).data,
);

/** <-- bundles */
/** Capacity Header --> */
export const getCapacityHeaders = createAsyncThunkCatchErrors(
  'admin/capacityHeaders/list',
  async () => (await AxiosAPI.get('/capacity-header/list?size=1000')).data,
);

export const createCapacityHeader = createAsyncThunkCatchErrors(
  'admin/capacityHeader/create',
  async (resource) => (await AxiosAPI.post('/capacity-header', resource)).data,
);
/** <-- Capacity Header */

/** countries --> */
export const getCountries = createAsyncThunkCatchErrors(
  'admin/countries/list',
  async () => (await AxiosAPI.get('/countries')).data,
);

export const getCountriesByThemeId = createAsyncThunkCatchErrors(
  'admin/countriesByThemeId',
  async (themeId: number) => (await AxiosAPI.get(`/themes/${themeId}/countries`)).data,
);

export const postThemeCountries = createAsyncThunkCatchErrors(
  'admin/themeCountries/update',
  async ({ themeId, content } : { themeId: number, content: any }) =>
    (await AxiosAPI.post(`/themes/${themeId}/countries`, content)).data,
);

export const getAllGoldThemes = createAsyncThunkCatchErrors(
  'admin/allGoldThemes/get',
  async () => (await AxiosAPI.get('/themes/product?active=true&product=website - gold&excludeProduct=add-ons')).data,
);

export const getAllThemes = createAsyncThunkCatchErrors(
  'admin/allThemes/get',
  async () => (await AxiosAPI.get('/themes/product?active=true&product=&excludeProduct=add-ons')).data,
);

export const getAllAddOns = createAsyncThunkCatchErrors(
  'admin/allAddOns/get',
  async () => (await AxiosAPI.get('/themes/product?product=add-ons')).data,
);
/** <-- countries */

export const updateMaintenanceMode = createAction<'ON' | 'OFF'>('admin/maintenanceMode/update');
export const createCapacityStatus = createAction<Omit<CapacityStatus, 'id'>>('admin/capacityStatuses/create');
export const setCurrentBundleByPayload = createAction<any>('admin/bundleByPayload/set');

export const adminSlice = createReducer(
  initialState,
  (builder) => {
    builder
      .addCase(updateMaintenanceMode, (state, action) => {
        state.maintenanceMode.content = action.payload;
      })
      .addCase(getThemeFeatures.fulfilled, (state, { payload }) => {
        state.themeFeatures.content = payload.content;
      })
      .addCase(getThemeBenefits.fulfilled, (state, { payload }) => {
        state.themeBenefits.content = payload.content;
      })
      .addCase(getThemeFamilies.fulfilled, (state, { payload }) => {
        state.themeFamilies.content = payload.content;
      })
      .addCase(getMarketCategories.fulfilled, (state, { payload }) => {
        state.marketCategories.content = payload.content.sort((a, b) => a.id - b.id);
      })
      .addCase(getBundles.fulfilled, (state, { payload }) => {
        state.bundles.content = payload.content;
      })
      .addCase(getThemeFamilyById.fulfilled, (state, { payload }) => {
        state.currentThemeFamily.content = { id: payload.id, name: payload.name };
      })
      .addCase(updateThemeFamily.fulfilled, (state, { payload }) => {
        const indexToUpdate = state.themeFamilies.content.findIndex(theme => theme.id === payload.id);
        const itemsCopy = [...state.themeFamilies.content];
        itemsCopy.splice(indexToUpdate, 1, payload);
        state.themeFamilies.content = itemsCopy;
      })
      .addCase(deleteThemeFamily.fulfilled, (state, { payload }) => {
        const indexToDelete = state.themeFamilies.content.findIndex(theme => theme.id === payload);
        const itemsCopy = [...state.themeFamilies.content];
        itemsCopy.splice(indexToDelete, 1);
        state.themeFamilies.content = itemsCopy;
      })
      .addCase(createThemeFamily.fulfilled, (state, { payload: { name } }) => {
        const id = state.themeFamilies.content.length + 1;
        const itemsCopy = [...state.themeFamilies.content];
        itemsCopy.push({ id, name });
        state.themeFamilies.content = itemsCopy;
      })
      .addCase(deleteThemeFeature.fulfilled, (state, { payload }) => {
        const indexToDelete = state.themeFeatures.content.findIndex(feature => feature.id === payload);
        const itemsCopy = [...state.themeFeatures.content];
        itemsCopy.splice(indexToDelete, 1);
        state.themeFeatures.content = itemsCopy;
      })
      .addCase(createThemeFeature.fulfilled, (state, { payload }) => {
        const id = state.themeFeatures.content.length + 1;
        const itemsToCopy = [...state.themeFeatures.content];
        itemsToCopy.push({ id, ...payload });
        state.themeFeatures.content = itemsToCopy;
      })
      .addCase(getThemeFeatureById.fulfilled, (state, { payload }) => {
        state.currentThemeFeature.content = payload;
      })
      .addCase(updateThemeFeature.fulfilled, (state, { payload }) => {
        const indexToUpdate = state.themeFeatures.content.findIndex(feature => feature.id === payload.id);
        const itemsCopy = [...state.themeFeatures.content];
        itemsCopy.splice(indexToUpdate, 1, payload);
        state.themeFeatures.content = itemsCopy;
      })
      .addCase(deleteThemeBenefit.fulfilled, (state, { payload }) => {
        const indexToDelete = state.themeBenefits.content.findIndex(benefit => benefit.id === payload);
        const itemsCopy = [...state.themeBenefits.content];
        itemsCopy.splice(indexToDelete, 1);
        state.themeBenefits.content = itemsCopy;
      })
      .addCase(createThemeBenefit.fulfilled, (state, { payload }) => {
        const id = state.themeBenefits.content.length + 1;
        const itemsToCopy = [...state.themeBenefits.content];
        itemsToCopy.push({ id, ...payload });
        state.themeBenefits.content = itemsToCopy;
      })
      .addCase(getThemeBenefitById.fulfilled, (state, { payload }) => {
        state.currentThemeBenefit.content = payload;
      })
      .addCase(updateThemeBenefit.fulfilled, (state, { payload }) => {
        const indexToUpdate = state.themeBenefits.content.findIndex(benefit => benefit.id === payload.id);
        const itemsCopy = [...state.themeBenefits.content];
        itemsCopy.splice(indexToUpdate, 1, payload);
        state.themeBenefits.content = itemsCopy;
      })
      .addCase(deleteMarketCategory.fulfilled, (state, { payload }) => {
        const indexToDelete = state.marketCategories.content.findIndex(category => category.id === payload);
        const itemsCopy = [...state.marketCategories.content];
        itemsCopy.splice(indexToDelete, 1);
        state.marketCategories.content = itemsCopy;
      })
      .addCase(createMarketCategory.fulfilled, (state, { payload }) => {
        const id = state.marketCategories.content.length + 1;
        const itemsToCopy = [...state.marketCategories.content];
        itemsToCopy.push({ id, ...payload });
        state.marketCategories.content = itemsToCopy;
      })
      .addCase(getMarketCategoryById.fulfilled, (state, { payload }) => {
        state.currentMarketCategory.content = payload;
      })
      .addCase(updateMarketCategory.fulfilled, (state, { payload }) => {
        const indexToUpdate = state.marketCategories.content.findIndex(category => category.id === payload.id);
        const itemsCopy = [...state.marketCategories.content];
        itemsCopy.splice(indexToUpdate, 1, payload);
        state.marketCategories.content = itemsCopy;
      })
      .addCase(getBusinessFunctionalities.fulfilled, (state, { payload }) => {
        state.businessFunctionalities.content = payload.content.sort((a, b) => a.id - b.id);
      })
      .addCase(deleteBusinessFunctionality.fulfilled, (state, { payload }) => {
        const indexToDelete = state.businessFunctionalities.content.findIndex(
          functionality => functionality.id === payload,
        );
        const itemsCopy = [...state.businessFunctionalities.content];
        itemsCopy.splice(indexToDelete, 1);
        state.businessFunctionalities.content = itemsCopy;
      })
      .addCase(createBusinessFunctionality.fulfilled, (state, { payload }) => {
        const itemsToCopy = [...state.businessFunctionalities.content];
        itemsToCopy.push({ ...payload });
        state.businessFunctionalities.content = itemsToCopy;
      })
      .addCase(getBusinessFunctionalityById.fulfilled, (state, { payload }) => {
        state.currentBusinessFunctionality.content = payload;
      })
      .addCase(updateBusinessFunctionality.fulfilled, (state, { payload }) => {
        const indexToUpdate = state.businessFunctionalities.content.findIndex(
          functionality => functionality.id === payload.id,
        );
        const itemsCopy = [...state.businessFunctionalities.content];
        itemsCopy.splice(indexToUpdate, 1, payload);
        state.businessFunctionalities.content = itemsCopy;
      })
      .addCase(getCapacityHeaders.fulfilled, (state, { payload }) => {
        state.capacityStatuses.content = payload.content.sort((a, b) => a.id - b.id);
      })
      .addCase(createCapacityHeader.fulfilled, (state, { payload }) => {
        const itemsToCopy = [...state.capacityStatuses.content];
        itemsToCopy.push({ ...payload });
        state.capacityStatuses.content = itemsToCopy;
      })
      .addCase(getCountries.fulfilled, (state, { payload }) => {
        state.countries.content = payload.content;
      })
      .addCase(getCountriesByThemeId.fulfilled, (state, { payload }) => {
        state.currentCountries.content = payload;
      })
      .addCase(createCapacityStatus, (state, { payload }) => {
        const id = state.capacityStatuses.content.length + 1;
        const itemsToCopy = [...state.capacityStatuses.content];
        itemsToCopy.push({ id, ...payload });
        state.capacityStatuses.content = itemsToCopy;
      })
      .addCase(deleteBundle.fulfilled, (state, { payload }) => {
        const indexToDelete = state.bundles.content.findIndex(bundle => bundle.id === payload);
        const itemsCopy = [...state.bundles.content];
        itemsCopy.splice(indexToDelete, 1);
        state.bundles.content = itemsCopy;
      })
      .addCase(createBundle.fulfilled, (state, { payload }) => {
        state.currentBundle.content = {
          active: payload.active,
          id: payload.id,
          mainThemeId: payload.mainThemeId,
          name: payload.name,
        };
      })
      .addCase(getBundleById.fulfilled, (state, { payload }) => {
        state.currentBundle.content = {
          active: payload.active,
          id: payload.id,
          mainThemeId: payload.mainThemeId,
          name: payload.name,
        };
      })
      .addCase(setCurrentBundleByPayload, (state, { payload }) => {
        state.currentBundle.content = payload;
      })
      .addCase(getAllAddOns.fulfilled, (state, { payload }) => {
        state.allAddOns.content = payload.map(({ id, title }) => ({ themeId: id, label: title }));
      })
      .addCase(getAllGoldThemes.fulfilled, (state, { payload }) => {
        state.allGoldThemes.content = payload.map(({ id, title }) => ({ themeId: id, label: title }));
      })
      .addCase(getAllThemes.fulfilled, (state, { payload }) => {
        state.allThemes.content = payload.map(({ id, title }) => ({ themeId: id, label: title }));
      })
      .addCase(getBundleItems.fulfilled, (state, { payload }) => {
        state.currentBundleAddOns.content = payload.content.filter(i => i.addon === true).map(
          ({
            id, bundleId, themeId, addon,
          }) => ({
            id, bundleId, themeId, addon,
          }),
        );
        state.currentBundleProducts.content = payload.content.filter(i => i.addon === false).map(
          ({
            id, bundleId, themeId, addon,
          }) => ({
            id, bundleId, themeId, addon,
          }),
        );
      })
      .addCase(updateBundle.fulfilled, (state, { payload }) => {
        state.currentBundle.content = {
          active: payload.active,
          id: payload.id,
          mainThemeId: payload.mainThemeId,
          name: payload.name,
        };
      });
  },
);

export const selectMaintenanceMode = (state: RootState) => state.admin.maintenanceMode.content;
export const selectChangeLog = (state: RootState) => state.admin.changeLog.content;
export const selectThemeFamilies = (state: RootState) => state.admin.themeFamilies.content;
export const selectCurrentThemeFamily = (state: RootState) => state.admin.currentThemeFamily.content;
export const selectThemeFeatures = (state: RootState) => state.admin.themeFeatures.content;
export const selectCurrentThemeFeature = (state: RootState) => state.admin.currentThemeFeature.content;
export const selectThemeBenefits = (state: RootState) => state.admin.themeBenefits.content;
export const selectCurrentThemeBenefit = (state: RootState) => state.admin.currentThemeBenefit.content;
export const selectMarketCategories = (state: RootState) => state.admin.marketCategories.content;
export const selectCurrentMarketCategory = (state: RootState) => state.admin.currentMarketCategory.content;
export const selectBusinessFunctionalities = (state: RootState) => state.admin.businessFunctionalities.content;
export const selectCurrentBusinessFunctionality = (
  state: RootState,
) => state.admin.currentBusinessFunctionality.content;
export const selectCountries = (state: RootState) => state.admin.countries.content;
export const selectCurrentCountries = (state: RootState) => state.admin.currentCountries.content;
export const selectProducts = (state: RootState) => state.admin.products.content;
export const selectCapacityStatuses = (state: RootState) => state.admin.capacityStatuses.content;
export const selectBundleAddons = (state: RootState) => state.admin.products.content;
export const selectBundleProducts = (state: RootState) => state.admin.products.content;
export const selectBundles = (state: RootState) => state.admin.bundles.content;
export const selectCurrentBundle = (state: RootState) => state.admin.currentBundle.content;
export const selectCurrentBundleAddOns = (state: RootState) => state.admin.currentBundleAddOns.content;
export const selectCurrentBundleProducts = (state: RootState) => state.admin.currentBundleProducts.content;
export const selectAllThemes = (state: RootState) => state.admin.allThemes.content;
export const selectAllAddOns = (state: RootState) => state.admin.allAddOns.content;
export const selectAllGoldThemes = (state: RootState) => state.admin.allGoldThemes.content;

export default adminSlice;
