import React from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Modal,
  Stack,
  Typography
} from '@mui/material';
import { Theme } from '@emotion/react';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import styled from '@emotion/styled';
import { IQThemeV2 } from '@gannettdigital/shared-react-components';
import { normalizeNewLines } from "shared/constants";

export interface GenericCardProps {
  labels: string[]; // Array of label names
  data: any; // JSON object with attribute matching to labels
  isDeleteIconRequired?: boolean;
  isEditIconRequired?: boolean;
  handleDelete: () => void;
  onEdit: () => void;
  onSelect: () => void;
  heading?: string;
}

type StyleProps = {
  theme?: Theme;
  error?: boolean;
};

const GenericCardStyled = styled(Card)<StyleProps>`
  padding: ${(props) => props.theme.spacing(1, 1, 0, 1)};
  border: 1px solid ${(props) =>
      props.error
          ? props.theme.palette.error.main
          : props.theme.palette.action.disabledBackground};
  margin-bottom: 16px;

  &:hover {
    background-color: ${(props) => props.theme.palette.primary.light};
  }
`;

const GenericCardContent = styled(CardContent)(({theme}) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
}));

const VerticalDivider = styled(Divider)(({theme}) => ({
  height: '50px',
  width: '1px',
  color: `${theme.palette.action.disabledBackground}`,

  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

const HorizontalDivider = styled(Divider)(({theme}) => ({
  color: `${theme.palette.action.disabledBackground}`,
  paddingTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
  [theme.breakpoints.up('sm')]: {
    display: 'none',
  },
}));

export default function GenericCard({
                                      labels,
                                      data,
                                      isDeleteIconRequired = true,
                                      isEditIconRequired = true,
                                      handleDelete,
                                      onEdit,
                                      onSelect,
                                      heading = ''
                                    }: GenericCardProps) {

  const [openModal, setOpenModal] = React.useState(false);

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const closeModalNoBackdropClick = (event, reason) => {
    if (reason && reason === 'backdropClick') return;
    setOpenModal(false);
  };

  const onDelete = () => {
    handleDelete();
    handleCloseModal();
  };

  // Function to format label
  const formatLabel = (label: string) => {
    // Use regex to split label based on camel case
    const words = label.split(/(?=[A-Z])/);
    // Capitalize first character of each word
    return words.map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
  };

  // Find the first non-empty label value
  const getFirstNonEmptyLabel = () => {
    for (let label of labels) {
      if (data[label]) {
        return data[label];
      }
    }
    return '';
  };

  const flexBasisPercentage = 100 / labels.length;

  return (
      <>
        <GenericCardStyled theme={IQThemeV2} onClick={onSelect}>
          {heading !== '' && (
              <Typography variant="subtitle1" marginLeft={2} marginTop={2} marginBottom={0}
                          sx={{
                            fontWeight: 'bold',
                          }}>
                {heading}
              </Typography>
          )}
          <GenericCardContent>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Stack direction="column" sx={{flexBasis: '90%'}}>
                {labels.map((label, index) => (
                    // Check if data for the label exists before rendering
                    (
                        <Stack key={index} direction="row"
                               sx={{flexBasis: `${flexBasisPercentage}%`}}>
                          <Typography variant="body1" fontWeight="bold" sx={{
                            marginRight: '5px',
                            textWrap: 'nowrap',
                            marginBottom: '8px'
                          }}>
                            {formatLabel(label)}:
                          </Typography>
                          <Typography
                              component="div"
                              variant="body1"
                              sx={{
                                fontFamily: 'Unify Sans',
                                fontSize: 16,
                                lineHeight: '24px',
                                wordBreak: 'break-word',
                                marginBottom: '8px'
                              }}
                          >
                            {Array.isArray(data[label]) ?
                                data[label].join(", ") :
                                normalizeNewLines(data[label]) || `Enter ${formatLabel(label)}`}
                          </Typography>
                        </Stack>
                    )
                ))}
              </Stack>
              <Stack direction="row" sx={{flexBasis: '10%', justifyContent: 'flex-end'}}>
                {isDeleteIconRequired && (
                    <Stack direction="row"
                           sx={{justifyContent: 'flex-end', padding: 0, marginRight: 1}}>
                      <DeleteIcon onClick={handleOpenModal} sx={{cursor: 'pointer'}}/>
                    </Stack>
                )}
                {isEditIconRequired && (
                    <Stack direction="row" sx={{justifyContent: 'flex-end'}}>
                      <EditIcon onClick={onEdit} sx={{cursor: 'pointer'}}/>
                    </Stack>
                )}
              </Stack>
            </Stack>
          </GenericCardContent>
        </GenericCardStyled>
        {openModal && (
            <Modal
                open={openModal}
                onClose={closeModalNoBackdropClick}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
              <Box
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 600,
                    bgcolor: 'background.paper',
                    borderRadius: '2px',
                    boxShadow: 24,
                    padding: '24px 8px 8px 24px',
                  }}
              >
                <Typography variant="h5" component="h2" mb={3}>
                  Remove Details
                </Typography>
                <Typography variant="body1">
                  Are you sure you want to remove&nbsp;
                  <strong>{getFirstNonEmptyLabel()}</strong>?
                </Typography>
                <Box sx={{display: 'flex', justifyContent: 'flex-end', gap: '16px', mt: 4}}>
                  <Button
                      variant="outlined"
                      sx={{borderRadius: '20px', textTransform: 'none'}}
                      onClick={handleCloseModal}
                  >
                    Cancel
                  </Button>
                  <Button
                      variant="contained"
                      sx={{borderRadius: '20px', textTransform: 'none'}}
                      onClick={onDelete}
                  >
                    Remove
                  </Button>
                </Box>
              </Box>
            </Modal>
        )}
      </>
  );
};