import { SetStateAction, useEffect, useState } from 'react';
import IosShareIcon from '@mui/icons-material/IosShare';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { Typography, Button, Box } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import GridItem from 'components/grid/GridItem';
import { useDispatch, useSelector } from 'react-redux';
import { selectOrderDetails, getOrderDetails } from 'services/myOrdersSlice';
import { IQLoadingSpinner } from '@gannettdigital/shared-react-components';
import Grid from '@mui/material/Grid';
import { useNavigationHandler } from 'hooks/useNavigationHandler';
import { Urls } from 'navigation/Urls';
import { selectIsBlueFlow, selectOrdersContent } from "services/ordersSlice";
import { getCurrentOffer, selectCurrentOffer } from "services/cefSlice";
import { OrderFlow } from "shared/constants";
import CsvFileGenerator from "pages/overview/sections/common/CsvFileGenerator";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { useLocation, useNavigate } from "react-router";

export const ActionItems = (
    clientInvite,
    itemId,
    offeringType = null,
    productData = null,
    disableEntryForm = false,
    openBusinessAndSalesAgent = () => {
    },
    setShowCIDModal?: React.Dispatch<SetStateAction<boolean>>,
) => {
  const dispatch = useDispatch();
  const navigate = useNavigationHandler();
  const {orderId, productId} = useParams();
  const navigateTo = useNavigate();
  const {pathname} = useLocation();
  const orderDetails = useSelector(selectOrderDetails);
  const [pdfLoading, setPdfLoading] = useState(false);
  const isBlueFlow = useSelector(selectIsBlueFlow);
  const currentOffer = useSelector(selectCurrentOffer);
  const [opportunityId, setOpportunityId] = useState(0);
  const {orderFlow} = useSelector(selectOrdersContent);

  useEffect(() => {
    dispatch(getCurrentOffer(itemId));
    dispatch(getOrderDetails(orderId));
  }, [orderId]);

  const handleEntryForm = async () => {
    setPdfLoading(true);
    await openBusinessAndSalesAgent();
    setTimeout(() => {
      window.print();
      setPdfLoading(false);
    }, 2000);
  };

  const handleShowCIDModal = () => {
    setShowCIDModal(true);
  };

  const handleExternalSubmissionView = () => {
    navigate.to(Urls.ExternalSubmissionView);
  };

  const handleEdit = () => {
    navigateTo(Urls.Overview, {state: {previousPath: pathname, banner: false}});
  }

  useEffect(() => {
    if (Object.keys(currentOffer).length > 0) setOpportunityId(currentOffer?.salesForceOfferObject?.opportunity);
  }, [currentOffer]);

  const handleSalesForceOpportunityButton = () => {
    if (isBlueFlow) {
      window.open(`${process.env.REACT_APP_NEON_FORCE_BLUE_LIGHTNING_URL}/${opportunityId}/view`);
    } else {
      window.open(`${process.env.REACT_APP_NEON_FORCE_LIGHTNING_URL}/${opportunityId}/view`);
    }
  };

  return (
      <GridItem sizes={[3, 9]} marginBottom={4}
                sx={{padding: '40px 0 0', borderTop: '1px solid #000000'}}>
        <Box sx={{display: 'flex', alignItems: 'center'}}>
          <Typography sx={{fontSize: '16px', fontWeight: '600'}}>Action items</Typography>
        </Box>
        <Box>
          <Grid item display="flex" flexDirection="row">
            {clientInvite && (
                <Link
                    to={`/public_emails/${orderId}/item/${productId || orderDetails?.[0]?.orderItem?.id}`}
                    replace
                    target="_blank" rel="noopener noreferrer"
                >
                  <Button variant="outlined" startIcon={<IosShareIcon/>}
                          sx={{marginRight: '10px', background: '#ffffff'}}
                  >
                    Client-Asset Invite
                  </Button>
                </Link>
            )}
            <Button variant="outlined"
                    startIcon={<FileDownloadOutlinedIcon/>}
                    sx={{marginRight: '10px', background: '#ffffff'}}
                    disabled={disableEntryForm || pdfLoading}
                    onClick={handleEntryForm}
            >
              Entry Form
              {pdfLoading && <Box ml={1}><IQLoadingSpinner size={18}/></Box>}
            </Button>
            {orderFlow !== OrderFlow.INTERNAL && (
                <Button variant="outlined" sx={{marginRight: '10px', background: '#ffffff'}}
                        onClick={handleSalesForceOpportunityButton}>
                  {isBlueFlow ? "Salesforce Case" : "Salesforce Opportunity"}
                </Button>
            )}
            {setShowCIDModal && (
                <>
                  <Button variant="outlined"
                          sx={{marginRight: '10px', background: '#ffffff'}}
                          onClick={handleShowCIDModal}
                  >
                    Select CID
                  </Button>
                  <Button variant="outlined"
                          startIcon={<OpenInNewIcon/>}
                          sx={{marginRight: '10px', background: '#ffffff'}}
                          onClick={handleExternalSubmissionView}
                  >
                    External Submission View
                  </Button>
                </>
            )}
            {
                offeringType && offeringType === 'LocalServiceAds'
                && <CsvFileGenerator productData={productData}/>
            }
            <Button variant="outlined"
                    startIcon={<ModeEditIcon/>}
                    sx={{marginRight: '10px', background: '#ffffff'}}
                    onClick={handleEdit}
            >
              Edit
            </Button>
          </Grid>
        </Box>
      </GridItem>
  );
};
