export const includedProducts = [
  'Website',
  'GoldSite',
  'E-Commerce Microsite',
  'CustomWebsite',
  'Campaign Landing Pages',
  'CustomLandingPage',
  'Chat',
  'Chat Platinum',
  'MasterAddon',
  'ProductsData',
  'PremiumListing',
  'SEOStandard',
  'SEOCustom',
  'SEOBlogStandard',
  'SEOBlogEnhanced',
  'SMMStandard',
  'SMMCustom',
  'GenericProduct',
];

export const addons = ['ExpandableMenu', 'PaymentForms', 'VideoBackground', 'Popup'];

export const mapTitle = {
  'Business Section': 'Business Section',
  'Sales Agent': 'Sales Agent',
  Website: 'Silver Website',
  GoldSite: 'Gold Website',
  'E-Commerce Microsite': 'E-Commerce Microsite',
  CustomWebsite: 'Custom Website',
  'Campaign Landing Pages': 'Campaign Landing Pages',
  CustomLandingPage: 'Custom Landing Page',
  Chat: 'Smart Chatbot Silver',
  'Chat Platinum': 'Live Chat Platinum',
  'Spanish Chat Add-on': 'Spanish Chat Add-on',
  'Chat Hybrid Addon': 'Chat Hybrid Add-on',
  MasterAddon: 'Add-ons',
  ProductsData: 'ProductsData',
  PremiumListing: 'US Premium Listings (1-9 Locations) w/ Yelp',
  'SEO Standard': 'Standard SEO',
  'SEO Custom': 'Custom SEO',
  'SEO Blog Standard': 'Blog Content (Standard)',
  'SEO Blog Enhanced': 'Blog Content (Enhanced)',
  'SMM Standard': 'Standard SMM',
  'SMM Custom': 'Custom SMM',
  'Customer Center': 'Customer Center',
  'Customer Center By Dash': 'Customer Center By Dash',
  'SEO Topic Location Addon': 'SEO Topic Location Addon',
  'SEO Content Pages': 'SEO Content Pages',
  'SMM Facebook Boosted Posts': 'SMM Facebook Boosted Posts',
  'SMM Instagram': 'SMM Add-on - Instagram',
  'SMM Infographics': 'SMM Add-on - Infographics',
  'SMM X (Twitter)': 'SMM X (Twitter)',
  'SMM Pinterest': 'SMM Add-on - Pinterest',
  'Shopping Ads': 'Shopping Ads',
  'Local Service Ads': 'Local Service Ads',
  Yelp: 'Yelp',
  'Google Tag Manager': 'Google Tag Manager',
  'SMB Retail GCI Only - Responsive Site': 'SMB Retail GCI Only - Responsive Site',
  GenericProduct: 'Product Details',
  'Custom Reporting': 'Custom Reporting',
  'Display Creative Service': 'Display Creative Service',
  'Custom Solutions - Spotify': 'Custom Solutions - Spotify',
  'Custom Solutions - Snapchat': 'Custom Solutions - Snapchat',
  'Custom Solutions - Linkedin': 'Custom Solutions - Linkedin',
  'Custom Tracking': 'Custom Tracking',
  'Custom Solutions - Pandora': 'Custom Solutions - Pandora',
  'Custom Solutions - Tiktok': 'Custom Solutions - Tiktok',
  'Custom Solutions - Twitter': 'Custom Solutions - Twitter',
  'SMB Retail GCI Only - SmartListings': 'SMB Retail GCI Only - SmartListings',
  'SMB Retail GCI Only - Visible Presence': 'SMB Retail GCI Only - Visible Presence',
  'Video Creative Service Package': 'Video Creative Service Package',
  'Site Retargeting With Lookalikes (GDN)': 'Site Retargeting With Lookalikes (GDN)',
  YouTube: 'You Tube',
  'SMB Retail GCI Only - 20 Page Site': 'SMB Retail GCI Only - 20 Page Site',
  'SMB Retail GCI Only - 5 Page Site': 'SMB Retail GCI Only - 5 Page Site',
  'Targeted Email': 'Targeted Email',
  'RL Branded Content': 'RL Branded Content',
  'Social Ads Lead Ads with Detailed Targeting': 'Social Ads Lead Ads with Detailed Targeting',
  'Social Ads Lead Ads': 'Social Ads Lead Ads',
  'Social Ads - Ad Engagement Add-On': 'Social Ads - Ad Engagement Add-On',
  'Search Engine Marketing': 'Search Engine Marketing',
  'PiQ Landing Pages': 'PerformanceiQ Attribution Landing Page',
  'Targeted Display': 'Targeted Display',
  'Targeted Display Legacy': 'Targeted Display Legacy',
  'Social Ads - Custom': 'Social Ads - Custom',
  'Social Ads Website Clicks': 'Social Ads Website Clicks',
  'Social Ads with Smart Optimization Technology': 'Social Ads with Smart Optimization Technology',
  'Social Ads Website Clicks with Detailed Targeting': 'Social Ads Website Clicks with Detailed Targeting',
  'CRM Integration Addon': 'CRM Integration Addon',
  'CS - Waze (Pilot)': 'CS - Waze (Pilot)',
  'Custom Campaign Landing Pages': 'Custom Campaign Landing Pages',
  'Enhanced Reporting Add-On': 'Enhanced Reporting Add-On',
  'Local Services Ads - Management Services': 'Local Services Ads - Management Services',
  'National - SEM': 'National - SEM',
  'Performance Max (PMAX BETA ONLY)': 'Performance Max (PMAX BETA ONLY)',
  'Website - Managed Services': 'Website - Managed Services',
  'Websites Content Migration': 'Websites Content Migration',
  'Premium Listings 10 Plus Physician Directories': 'Premium Listings 10 Plus Physician Directories',
  'Premium Listings 10 Plus': 'Premium Listings 10 Plus',
  'GetCreative Service Fee': 'GetCreative Service Fee',
  'Social Ads Retargeting': 'Social Ads Retargeting',
  'Display Creative Service Custom': 'Display Creative Service Custom',
  'Website Add-On - Content Pages': 'Website Add-On - Content Pages',
  'Promotions Page And Creatives (ReachLocal Beta ONLY)': 'Promotions Page And Creatives (ReachLocal Beta ONLY)',
  'AUS Tiktok': 'AUS Tiktok',
  'AUS Total Track': 'AUS Total Track',
  'AUS Linkedin': 'AUS Linkedin',
  'AUS Custom Facebook': 'AUS Custom Facebook',
  'AUS Performance Max': 'AUS Performance Max',
  'AUS Youtube': 'AUS Youtube',
  'AUS Live Chat': 'AUS Live Chat',
  'Multi-Media Creatives': 'Multi-Media Creatives',
  'AUS Demand Gen': 'AUS Demand Gen',
  'AUS Site': 'AUS Site',
  'AUS Video Creative Service': 'AUS Video Creative Service',
  'AUS Google Display Network': 'AUS Google Display Network',
  'AUS Premium Listings 1-9': 'AUS Premium Listings 1-9',
  'AUS Social Catalogue': 'AUS Social Catalogue',
  'AUS XMO Social Upgrade': 'AUS XMO Social Upgrade',
  'AUS Campaign Landing Pages': 'AUS Campaign Landing Pages',
  'AUS Social - FB Conversions': 'AUS Social - FB Conversions',
};
