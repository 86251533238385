/* eslint-disable max-len */
import i18n from 'i18n/i18n';

import {RJSFSchema} from '@rjsf/utils';
import Dropdown from 'pages/generic-product/details/custom-fields/Dropdown';
import {Grid, Typography} from "@mui/material";
import React from "react";
import {validateUrl} from "pages/generic-product/details/custom-fields/UrlTextField";

const urlValidationParameter = ['https://facebook.com', 'https://www.facebook.com'];
const {t} = i18n;

const validateRequiredFormField = (formData, fieldName, errors) => {
  if (formData?.[fieldName] === '') {
    errors[fieldName].addError(t('pages.ausProduct.tracking.requiredFieldErrorMessage'));
    errors.addError(true);
  }
};

export const FacebookWebsiteConversionSchema: RJSFSchema | any = (data) => ({
  title: t('pages.ausProduct.facebookWebsiteConversion.title'),
  type: 'object',
  required: [
    'websiteAction',
    'facebookPageUrl',
    'url',
    'trackingRequirements',
    'hasClientFacebookPixel',
  ],
  properties: {
    facebookPageUrl: {
      type: 'string',
      title: t('pages.ausProduct.facebookWebsiteConversion.facebookPageUrlLabel'),
      default: '',
      customUrlValidation: true,
      urlValidationParameter,
      urlValidationErrorText: t('pages.ausProduct.customFacebook.facebookPageUrlErrorMessage'),
      description: t('pages.ausProduct.customFacebook.facebookPageUrlDescription'),
    },
    defaultLandingPage: {
      type: 'string',
      title: t('pages.ausProduct.facebookWebsiteConversion.defaultLandingPageLabel'),
      default: data?.defaultLandingPage || ''
    },
    privacyPolicyAnchorText: {
      type: 'string',
    },
    websiteAction	: {
      type: 'string',
      title: t('pages.ausProduct.facebookWebsiteConversion.websiteActionLabel'),
      default: data?.websiteAction || ''
    },
    url	: {
      type: 'string',
      title: t('pages.ausProduct.facebookWebsiteConversion.urlLabel'),
      default: data?.url || ''
    },
    automaticPlacements: {
      type: 'string',
      title: t('pages.ausProduct.facebookWebsiteConversion.automaticPlacementLabel'),
      default: data?.automaticPlacements || '',
      enum: [
        '',
        t('pages.ausProduct.facebookWebsiteConversion.automaticPlacementOpt1'),
        t('pages.ausProduct.facebookWebsiteConversion.automaticPlacementOpt2'),
      ],
    },
    trackingRequirements: {
      type: 'string',
      title: t('pages.ausProduct.linkedin.trackingRequirementsLabel'),
      description: t('pages.ausProduct.xmoUpgradeSocial.tracking.trackingRequirementsDescription'),
      default: data?.trackingRequirements || '',
      enum: [
        '',
        t('pages.ausProduct.linkedin.trackingRequirementsOpt1'),
        t('pages.ausProduct.linkedin.trackingRequirementsOpt2'),
        t('pages.ausProduct.linkedin.trackingRequirementsOpt3'),
        t('pages.ausProduct.linkedin.trackingRequirementsOpt4'),
      ],
    },
    areYouHappy: {
      type: 'string',
      title: t('pages.ausProduct.facebookWebsiteConversion.areYouHappyLabel'),
      default: data?.areYouHappy || '',
      enum: [
        '',
        t('pages.ausProduct.facebookWebsiteConversion.areYouHappyOpt'),
        t('pages.ausProduct.facebookWebsiteConversion.noOpt'),
      ],
    },
    hasClientFacebookPixel: {
      type: 'string',
      title: t('pages.ausProduct.facebookWebsiteConversion.hasClientFacebookPixelLabel'),
      description : t('pages.ausProduct.facebookWebsiteConversion.hasClientFacebookPixelDescription'),
      default: data?.hasClientFacebookPixel || '',
      enum: [
        '',
        t('pages.ausProduct.facebookWebsiteConversion.yesOpt'),
        t('pages.ausProduct.facebookWebsiteConversion.noOpt'),
      ],
    },
    isTacticalOrFlightedCampaign: {
      type: 'string',
      title: t('pages.ausProduct.facebookWebsiteConversion.isTacticalOrFlightedCampaignLabel'),
      description : t('pages.ausProduct.facebookWebsiteConversion.isTacticalOrFlightedCampaignDescription'),
      default: data?.isTacticalOrFlightedCampaign || '',
      enum: [
        '',
        t('pages.ausProduct.facebookWebsiteConversion.yesOpt'),
        t('pages.ausProduct.facebookWebsiteConversion.noOpt'),
      ],
    },
  },
  dependencies: {
    automaticPlacements: {
      oneOf: [
        {
          properties: {
            automaticPlacements: {
              enum: [t('pages.ausProduct.xmoUpgradeSocial.targeting.manualPlacementOpt')],
            },
            manualAdPlacement: {
              type: 'array',
              title: t('pages.ausProduct.xmoUpgradeSocial.targeting.manualAdPlacementLabel'),
              description: t('pages.ausProduct.facebookWebsiteConversion.manualAdPlacementDescription'),
              items: {
                type: 'string',
                enum: [
                  t('pages.ausProduct.xmoUpgradeSocial.targeting.manualAdPlacementOpt1'),
                  t('pages.ausProduct.xmoUpgradeSocial.targeting.manualAdPlacementOpt2'),
                  t('pages.ausProduct.xmoUpgradeSocial.targeting.manualAdPlacementOpt3'),
                  t('pages.ausProduct.xmoUpgradeSocial.targeting.manualAdPlacementOpt4'),
                  t('pages.ausProduct.xmoUpgradeSocial.targeting.manualAdPlacementOpt5'),
                ],
              },
              uniqueItems: true,
              minItems: 1
            },
          },
        },
      ],
    },
    areYouHappy: {
      oneOf: [
        {
          properties: {
            areYouHappy: {
              enum: [t('pages.ausProduct.youtube.noOpt')],
            },
            utmStringCreationCorrectlyFormattedDescription: {
              type: 'string',
              title: t('pages.ausProduct.youtube.utmStringCreationCorrectlyFormattedDescription'),
            },
            source: {
              type: 'string',
              title: t('pages.ausProduct.youtube.sourceLabel'),
              default: data?.source || '',
            },
            medium: {
              type: 'string',
              title: t('pages.ausProduct.youtube.mediumLabel'),
              default: data?.medium || '',
            },
            campaignName: {
              type: 'string',
              title: t('pages.ausProduct.youtube.campaignNameForUtmStringCreationCorrectlyFormattedLabel'),
              default: data?.campaignName || '',
            },
          },
          required: [
            'source',
            'medium',
            'campaignName'
          ],
        },
      ],
    },
    hasClientFacebookPixel: {
      oneOf: [
        {
          properties: {
            hasClientFacebookPixel: {
              enum: [t('pages.ausProduct.facebookWebsiteConversion.yesOpt')],
            },
            clientFacebookPixelId: {
              type: 'string',
              title: t('pages.ausProduct.facebookWebsiteConversion.clientFacebookPixelIdLabel'),
              default: data?.clientFacebookPixelId || '',
            },
          },
          required: [
            'clientFacebookPixelId',
          ],
        },
      ],
    },
  }
});

const urlTextFieldKeys = [
  'facebookPageUrl',
  'defaultLandingPage',
  'url',
]

const dropdownFieldKeys = [
  'automaticPlacements',
  'trackingRequirements',
  'areYouHappy',
  'hasClientFacebookPixel',
  'isTacticalOrFlightedCampaign',
];

const inputTextFieldKeys = [
  'websiteAction',
  'source',
  'medium',
  'campaignName'
];

const dropdownFields = dropdownFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: {
    'ui:widget': (props) => (
        <Dropdown
            {...props}
            errorMessage={t('pages.ausProduct.tracking.requiredFieldErrorMessage')}
        />
    ),
  },
}), {});

const inputTextField = inputTextFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: {
    'ui:widget': 'textInputField'
  },
}), {});

const urlTextFields = urlTextFieldKeys.reduce((o, key) => ({
  ...o, [key]: {'ui:widget': 'UrlTextWidget'},
}), {});

export const FacebookWebsiteConversionUISchema = {
  'ui:order': [
    'facebookPageUrl',
    'defaultLandingPage',
    'privacyPolicyAnchorText',
    'websiteAction',
    'url',
    'automaticPlacements',
    'manualAdPlacement',
    'trackingRequirements',
    'areYouHappy',
    'utmStringCreationCorrectlyFormattedDescription',
    'source',
    'medium',
    'campaignName',
    'hasClientFacebookPixel',
    'clientFacebookPixelId',
    'isTacticalOrFlightedCampaign',
  ],

  clientFacebookPixelId:{
    'ui:widget': 'integerInputField'
  },

  privacyPolicyAnchorText: {
    'ui:widget': (props) => (
        <Grid item xs={9} mt={1}>
          <Typography fontSize={14}>
            {t('pages.ausProduct.facebookWebsiteConversion.des1')}
            <a href={t('pages.ausProduct.facebookWebsiteConversion.link1')} target="_blank"
               rel="noreferrer">
              <Typography component="span" color="primary" fontSize={14}>
                {t('pages.ausProduct.facebookWebsiteConversion.link1')}
              </Typography>
            </a>
            {t('pages.ausProduct.facebookWebsiteConversion.des2')}
            <a href={t('pages.ausProduct.facebookWebsiteConversion.link2')} target="_blank"
               rel="noreferrer">
              <Typography component="span" color="primary" fontSize={14}>
                {t('pages.ausProduct.facebookWebsiteConversion.link2')}
              </Typography>
            </a>
          </Typography>
        </Grid>
    ),
  },

  manualAdPlacement:{
    'ui:widget': 'checkBoxField'
  },

  utmStringCreationCorrectlyFormattedDescription: {
    'ui:widget': 'textLabel',
    'ui:style': {
      marginBottom: '-15px',
    },
  },
  ...dropdownFields,
  ...inputTextField,
  ...urlTextFields
};

export const FacebookWebsiteConversionCustomValidate = (formData: any, errors, uiSchema) => {
  if (formData?.facebookPageUrl === '' || !urlValidationParameter.some(url => formData?.facebookPageUrl?.startsWith(url))) {
    errors.facebookPageUrl.addError(t('pages.ausProduct.customFacebook.facebookPageUrlErrorMessage'));
    errors.addError(true);
  }
  if (formData?.defaultLandingPage && (formData?.defaultLandingPage === '' || !validateUrl(formData?.defaultLandingPage))) {
    errors.defaultLandingPage.addError(t('pages.genericUrlErrorMessage.urlErrorMessage'));
    errors.addError(true);
  }
  if (formData?.areYouHappy === t('pages.ausProduct.facebookWebsiteConversion.noOpt')) {
    validateRequiredFormField(formData, 'source', errors,);
    validateRequiredFormField(formData, 'medium', errors,);
    validateRequiredFormField(formData, 'campaignName', errors,);
  }
  if (formData?.hasClientFacebookPixel === t('pages.ausProduct.facebookWebsiteConversion.yesOpt')) {
    validateRequiredFormField(formData, 'clientFacebookPixelId', errors,);
  }
  validateRequiredFormField(formData, 'websiteAction', errors,);
  validateRequiredFormField(formData, 'url', errors,);
  validateRequiredFormField(formData, 'trackingRequirements', errors,);
  validateRequiredFormField(formData, 'hasClientFacebookPixel', errors,);
  return errors;
};