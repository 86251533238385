import i18n from 'i18n/i18n';
import {RJSFSchema} from "@rjsf/utils";

const {t} = i18n;

const validateRequiredFormField = (formData, fieldName, errors) => {
  if (formData?.[fieldName] === '') {
    errors[fieldName].addError(t('pages.ausProduct.tracking.requiredFieldErrorMessage'));
    errors.addError(true);
  }
};
export const TargetingSocialFbConversionsSchema: RJSFSchema | any = (data) => ({
  title: t('pages.ausProduct.TargetingSocialFbConversions.title'),
  type: 'object',
  required: [
    'campaignTargeting'
  ],
  properties: {
    campaignTargeting: {
      type: 'string',
      title: t('pages.ausProduct.TargetingSocialFbConversions.campaignTargetingLabel'),
      description: t('pages.ausProduct.youtubeTargeting.locationTargetingTypeDescription'),
      default: data?.campaignTargeting || '',
      enum: [
        '',
        t('pages.ausProduct.youtubeTargeting.nationalOpt'),
        t('pages.ausProduct.youtubeTargeting.stateOpt'),
        t('pages.ausProduct.youtubeTargeting.radiusOpt'),
        t('pages.ausProduct.youtubeTargeting.postcodeOpt'),
        t('pages.ausProduct.youtubeTargeting.cityOpt'),
      ]
    },
    allTypesOfAdSets: {
      type: 'array',
      title: t('pages.ausProduct.TargetingSocialFbConversions.allTypesOfAdSetsLabel'),
      items: {
        type: 'string',
        enum: [
          t('pages.ausProduct.TargetingSocialFbConversions.broadTargetingOpt'),
          t('pages.ausProduct.TargetingSocialFbConversions.detailedTargetingOpt'),
          t('pages.ausProduct.TargetingSocialFbConversions.retargetingOpt'),
          t('pages.ausProduct.TargetingSocialFbConversions.lookalikeOpt'),
        ],
      },
      uniqueItems: true,
      minItems: 1,
    },
    helperText: {
      type: 'string',
      title: '',
      description: t('pages.ausProduct.TargetingSocialFbConversions.helperText'),
    },
    nationalPublisherTargeting: {
      type: 'string',
      title: t('pages.ausProduct.youtubeTargeting.nationalPublisherTargetingLabel'),
      default: data?.nationalPublisherTargeting || '',
      enum: [
        '',
        t('pages.ausProduct.youtubeTargeting.nationalPublisherTargetingOpt1'),
        t('pages.ausProduct.youtubeTargeting.nationalPublisherTargetingOpt2'),
      ]
    },
  },
  dependencies: {
    campaignTargeting: {
      oneOf: [
        {
          properties: {
            campaignTargeting: {
              enum: [t('pages.ausProduct.youtubeTargeting.nationalOpt')],
            },
            cityPublisher: {
              type: 'string',
              title: t('pages.ausProduct.youtubeTargeting.cityPublisherDescription'),
            },
            selectedCountry: {
              type: 'string',
              title: t('pages.ausProduct.youtubeTargeting.selectedCountryLabel'),
              default: data?.selectedCountry || '',
              enum: [
                '',
                t('pages.ausProduct.youtubeTargeting.australiaOpt'),
                t('pages.ausProduct.youtubeTargeting.newZealandOpt'),
                t('pages.ausProduct.youtubeTargeting.OtherOpt'),
              ]
            },
          },
        },
        {
          properties: {
            campaignTargeting: {
              enum: [t('pages.ausProduct.youtubeTargeting.stateOpt')],
            },
            targetedStates: {
              type: 'array',
              title: t('pages.ausProduct.youtubeTargeting.targetedStatesLabel'),
              description: t('pages.ausProduct.youtubeTargeting.targetedStatesDescription'),
              items: {
                type: 'string',
                enum: [
                  t('pages.ausProduct.youtubeTargeting.australianCapitalTerritoryOpt'),
                  t('pages.ausProduct.youtubeTargeting.newSouthWalesOpt'),
                  t('pages.ausProduct.youtubeTargeting.northernTerritoryOpt'),
                  t('pages.ausProduct.youtubeTargeting.queenslandOpt'),
                  t('pages.ausProduct.youtubeTargeting.southAustraliaOpt'),
                  t('pages.ausProduct.youtubeTargeting.tasmaniaOpt'),
                  t('pages.ausProduct.youtubeTargeting.victoriaOpt'),
                  t('pages.ausProduct.youtubeTargeting.westernAustraliaOpt'),
                  t('pages.ausProduct.youtubeTargeting.northlandOpt'),
                  t('pages.ausProduct.youtubeTargeting.aucklandOpt'),
                  t('pages.ausProduct.youtubeTargeting.bayOfPlentyOpt'),
                  t('pages.ausProduct.youtubeTargeting.canterburyOpt'),
                  t('pages.ausProduct.youtubeTargeting.gisborneOpt'),
                  t('pages.ausProduct.youtubeTargeting.hawkesBayOpt'),
                  t('pages.ausProduct.youtubeTargeting.manawatuWanganuiOpt'),
                  t('pages.ausProduct.youtubeTargeting.marlboroughOpt'),
                  t('pages.ausProduct.youtubeTargeting.nelsonOpt'),
                  t('pages.ausProduct.youtubeTargeting.otagoOpt'),
                  t('pages.ausProduct.youtubeTargeting.southlandOpt'),
                  t('pages.ausProduct.youtubeTargeting.taranakiOpt'),
                  t('pages.ausProduct.youtubeTargeting.tasmanOpt'),
                  t('pages.ausProduct.youtubeTargeting.waikatoOpt'),
                  t('pages.ausProduct.youtubeTargeting.wellingtonOpt'),
                  t('pages.ausProduct.youtubeTargeting.westCoastOpt'),
                ],
              },
              uniqueItems: true,
            },
          },
        },
        {
          properties: {
            campaignTargeting: {
              enum: [t('pages.ausProduct.youtubeTargeting.radiusOpt')],
            },
            providedRadius: {
              type: 'string',
              title: t('pages.ausProduct.youtubeTargeting.providedRadiusLabel'),
              default: data?.providedRadius || '',
            },
            streetNumber: {
              type: 'string',
              title: t('pages.ausProduct.youtubeTargeting.streetNumberLabel'),
              default: data?.streetNumber || '',
            },
            streetName: {
              type: 'string',
              title: t('pages.ausProduct.youtubeTargeting.streetNameLabel'),
              default: data?.streetName || '',
            },
            cityOrSuburb: {
              type: 'string',
              title: t('pages.ausProduct.youtubeTargeting.cityOrSuburbLabel'),
              default: data?.cityOrSuburb || '',
            },
            stateOrProvince: {
              type: 'string',
              title: t('pages.ausProduct.youtubeTargeting.stateOrProvinceLabel'),
              default: data?.stateOrProvince || '',
            },
            postalCode: {
              type: 'string',
              title: t('pages.ausProduct.youtubeTargeting.postalCodeLabel'),
              default: data?.postalCode || '',
            },
            country: {
              type: 'string',
              title: t('pages.ausProduct.youtubeTargeting.countryLabel'),
              default: data?.country || '',
            },
          },
          required: [
            'providedRadius',
            'streetNumber',
            'streetName',
            'cityOrSuburb',
            'stateOrProvince',
            'postalCode',
            'country',
          ]
        },
        {
          properties: {
            campaignTargeting: {
              enum: [t('pages.ausProduct.youtubeTargeting.postcodeOpt')],
            },
            postcode: {
              type: 'string',
              title: t('pages.ausProduct.targetingIndented.postcodeLabel'),
              description: t('pages.ausProduct.targetingIndented.postcodeDescription'),
              default: '',
            },
          },
          required: ['postcode']
        },
        {
          properties: {
            campaignTargeting: {
              enum: [t('pages.ausProduct.youtubeTargeting.cityOpt')],
            },
            cityTargetingRequirements: {
              type: 'string',
              title: t('pages.ausProduct.targetingIndented.cityTargetingRequirements'),
              description: t('pages.ausProduct.targetingIndented.cityTargetingRequirementsDescription'),
              default: data?.cityTargetingRequirements || '',
            },
          },
          required: ['cityTargetingRequirements']
        },
      ],
    },
    allTypesOfAdSets: {
      allOf: [
        {
          if: {
            properties: {
              allTypesOfAdSets: {
                contains: {
                  enum: [
                    t('pages.ausProduct.TargetingSocialFbConversions.broadTargetingOpt'),
                  ],
                },
              },
            },
          },
          then: {
            properties: {
              broadTargetingDemographics: {
                type: 'string',
                title: t('pages.ausProduct.TargetingSocialFbConversions.broadTargetingDemographicsLabel'),
                description: t('pages.ausProduct.TargetingSocialFbConversions.broadTargetingDemographicsDescription'),
                default: data?.broadTargetingDemographics || '',
              },
            },
          },
        },
        {
          if: {
            properties: {
              allTypesOfAdSets: {
                contains: {
                  enum: [t('pages.ausProduct.TargetingSocialFbConversions.detailedTargetingOpt')],
                },
              },
            },
          },
          then: {
            properties: {
              detailedTargetingAudiences: {
                type: 'string',
                title: t('pages.ausProduct.TargetingSocialFbConversions.detailedTargetingAudiencesLabel'),
                description: t('pages.ausProduct.TargetingSocialFbConversions.detailedTargetingAudiencesDescription'),
                default: data?.detailedTargetingAudiences || '',
              },
              detailedTargetingAgeAndGender: {
                type: 'string',
                title: t('pages.ausProduct.TargetingSocialFbConversions.detailedTargetingAgeAndGenderLabel'),
                description: t('pages.ausProduct.TargetingSocialFbConversions.detailedTargetingAgeAndGenderDescription'),
                default: data?.detailedTargetingAgeAndGender || '',
              },
            },
          },
        },
      ],
    },
  }
});

const dropdownFieldKeys = [
  'campaignTargeting',
  'selectedCountry',
  'nationalPublisherTargeting',
];

const inputFieldKeys = [
  'streetName',
  'cityOrSuburb',
  'stateOrProvince',
  'country',
  'cityTargetingRequirements',
  'nationalPublisherTargeting',
  'targetAudiences',
  'customIntentAudience',
  'describeYourAudience',
  'broadTargetingDemographics',
  'detailedTargetingAudiences',
  'detailedTargetingAgeAndGender'
];

const checkBoxFieldKey = [
  'targetedStates',
  'allTypesOfAdSets',
];
const textLabelKey = [
  'cityPublisher',
  'fullListHelpText',
  'helperText',
];

const integerInputFieldKey = [
  'providedRadius',
  'streetNumber',
  'postalCode',
  'postcode',
];

const dropdownFields = dropdownFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: {'ui:widget': 'dropdown'},
}), {});

const inputFields = inputFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: {'ui:widget': 'textInputField'},
}), {});

const checkBoxField = checkBoxFieldKey.reduce((o, key) => ({
  ...o,
  [key]: {'ui:widget': 'checkBoxField'},
}), {});

const textLabel = textLabelKey.reduce((o, key) => ({
  ...o,
  [key]: {'ui:widget': 'textLabel'},
}), {});

const integerInputField = integerInputFieldKey.reduce((o, key) => ({
  ...o,
  [key]: {'ui:widget': 'integerInputField'},
}), {});

export const TargetingSocialFbConversionsUISchema = {
  'ui:order': [
    'campaignTargeting',
    'cityPublisher',
    'selectedCountry',
    'targetedStates',
    'providedRadius',
    'streetNumber',
    'streetName',
    'cityOrSuburb',
    'stateOrProvince',
    'postalCode',
    'country',
    'cityTargetingRequirements',
    'postcode',
    'allTypesOfAdSets',
    'broadTargetingDemographics',
    'detailedTargetingAudiences',
    'detailedTargetingAgeAndGender',
    'helperText',
    'nationalPublisherTargeting'
  ],

  fullListLink: {
    'ui:widget': 'anchorTextLabel',
  },

  ...inputFields,
  ...dropdownFields,
  ...checkBoxField,
  ...textLabel,
  ...integerInputField,
};

export const TargetingSocialFbConversionsCustomValidate = (formData: any, errors, uiSchema) => {
  validateRequiredFormField(formData, 'campaignTargeting', errors,);

  if (formData?.campaignTargeting === t('pages.ausProduct.youtubeTargeting.radiusOpt')) {
    validateRequiredFormField(formData, 'providedRadius', errors,);
    validateRequiredFormField(formData, 'streetNumber', errors,);
    validateRequiredFormField(formData, 'streetName', errors,);
    validateRequiredFormField(formData, 'cityOrSuburb', errors,);
    validateRequiredFormField(formData, 'stateOrProvince', errors,);
    validateRequiredFormField(formData, 'postalCode', errors,);
    validateRequiredFormField(formData, 'country', errors,);
  }

  if (formData?.campaignTargeting === t('pages.ausProduct.youtubeTargeting.postcodeOpt')) {
    validateRequiredFormField(formData, 'postcode', errors,);
  }

  if (formData?.campaignTargeting === t('pages.ausProduct.youtubeTargeting.cityOpt')) {
    validateRequiredFormField(formData, 'cityTargetingRequirements', errors,);
  }
  return errors;
};
