/* eslint-disable max-len */
import i18n from 'i18n/i18n';
import {RJSFSchema} from '@rjsf/utils';

const {t} = i18n;

const validateRequiredFormField = (formData, fieldName, errors) => {
  if (formData?.[fieldName] === '') {
    errors[fieldName].addError(t('pages.ausProduct.tracking.requiredFieldErrorMessage'));
    errors.addError(true);
  }
};

export const WebsiteConversionsQualificationSchema: RJSFSchema | any = (data) => ({
  title: t('pages.ausProduct.websiteConversionsQualification.title'),
  type: 'object',
  required: [
    'isSolutionCorrect',
    'isTotalBudget1500OrMore'
  ],
  properties: {

    isSolutionCorrect: {
      type: 'string',
      title: t('pages.ausProduct.websiteConversionsQualification.isSolutionCorrectLabel'),
      description: t('pages.ausProduct.websiteConversionsQualification.isSolutionCorrectDescription'),
      default: '',
      items: {
        enum: [
          t('pages.ausProduct.websiteConversionsQualification.yesOpt'),
          t('pages.ausProduct.websiteConversionsQualification.noOpt')
        ],
      },
    },

    isTotalBudget1500OrMore: {
      type: 'string',
      title: t('pages.ausProduct.websiteConversionsQualification.isTotalBudget1500OrMoreLabel'),
      description: t('pages.ausProduct.websiteConversionsQualification.isTotalBudget1500OrMoreDescription'),
      default: '',
      items: {
        enum: [
          t('pages.ausProduct.websiteConversionsQualification.isTotalBudget1500OrMoreOpt1'),
          t('pages.ausProduct.websiteConversionsQualification.isTotalBudget1500OrMoreOpt2')
        ],
      },
    },
  },
});

const radioTextFieldKeys = [
  'isSolutionCorrect',
  'isTotalBudget1500OrMore'
];

const radioTextField = radioTextFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: {
    'ui:widget': 'radioButton'
  },
}), {});

export const WebsiteConversionsQualificationUISchema = {
  'ui:order': [
    'isSolutionCorrect',
    'isTotalBudget1500OrMore',
  ],
  ...radioTextField,
};

export const WebsiteConversionsQualificationCustomValidate = (formData: any, errors, uiSchema) => {
  validateRequiredFormField(formData, 'isSolutionCorrect', errors,);
  validateRequiredFormField(formData, 'isTotalBudget1500OrMore', errors,);
  return errors;
};