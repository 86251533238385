import {
  Box, IconButton, MenuItem, Select, TableCell, TableRow, Typography,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { CartItem, selecteCartAddons } from 'services/cartSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { setImageUrl } from 'services/themesSlice';
import { selectOpportunity } from 'services/cefSlice';
import { setOrderItemTypeOfSale } from 'services/ordersSlice';
import EditAddonsModal from './EditAddonsModal';
import { camelCaseToFlat, getAddonsList, getProductName } from './ShoppingCart';
import { TypesOfSale } from './Constants';

interface RowProps {
  item: CartItem
  onRemove: () => void
}

const goldProduct = 'GoldSite';

export const displayThemeName = (item) => {
  if (item.healthcare) return 'Healthcare';
  if (item.productName === 'SEOStandard') return 'Standard';
  if (['SEOCustom', 'SMMCustom'].includes(item.productName)) return 'Custom';
  if (item.productName === 'SMMStandard') return 'Standard';
  if (['DMSNextCustomerCenter', 'DMSNextCustomerCenterByDash'].includes(item.productName)) return '';
  return item.themeName;
};

const CartItemRow = ({
  item, onRemove,
}: RowProps) => {
  const dispatch = useDispatch();
  const cartAddons = useSelector(selecteCartAddons);
  const opportunity = useSelector(selectOpportunity);
  const [productName, setProductName] = useState(getProductName(item));
  const [typeOfSale, setTypeOfSale] = useState(item?.orderItem?.saleType || item?.saleType || TypesOfSale[0].value);
  const isGoldSite = item.productName === goldProduct;
  const fallBackThumbnailUrl = '/assets/cb-image-logomark.jpg';

  const typeOfSaleChange = async (e) => {
    setTypeOfSale(e.target.value);
    await dispatch(setOrderItemTypeOfSale({ orderItemId: item?.orderItem?.id || item.id, content: e.target.value }));
  };

  const [showEditModal, setShowEditModal] = useState(false);
  const formatDate = (value: Date | number) => {
    if (!value) return 'TBD';
    if (typeof value === 'number') {
      const tempValue = new Date(value);
      return `${tempValue.toLocaleString('default', { month: 'short' })} ${tempValue.getDate()}`;
    }
    return `${value.toLocaleString('default', { month: 'short' })} ${value.getDate()}`;
  };

  const getImageUrl = (item) => {
    if (item?.productType === 'Addon') {
      if (!item.thumbnailUrl) {
        return fallBackThumbnailUrl;
      }
      return `/assets/addons/${item.thumbnailUrl ? item.thumbnailUrl : fallBackThumbnailUrl}`;
    }
    if (!item.thumbnailUrl) {
      return fallBackThumbnailUrl;
    }
    return setImageUrl(item.themeId, item.thumbnailUrl, 'composite_screenshot');
  };

  useEffect(() => {
    setProductName(getProductName(item));
    if (Object.keys(opportunity).length > 0 && isGoldSite) {
      // eslint-disable-next-line max-len
      const filterSolution = opportunity?.offers?.filter(offer => offer?.neonProducts?.[0]?.neonProductName === goldProduct);
      if (filterSolution.length > 0) {
        setProductName(`Gold Site - ${filterSolution[0].neonProducts[0].bundle}`);
      }
    }
  }, [opportunity, item]);

  const addWeeks = (timestamp, weeks) => {
    if (weeks) {
      const date = new Date(timestamp);
      date.setDate(date.getDate() + 7 * weeks);
      return date.getTime();
    }

    return timestamp || null;
  };

  return (
    <>
      <TableRow
        classes={{ root: 'product-row' }}
        key={item?.orderItem?.offeringType || item?.offeringType}
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      >
        <TableCell component="th" scope="row">
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
          }}
          >
            <img style={{ width: '100px', height: '100px', marginRight: '20px' }}
              src={getImageUrl(item)}
              alt={item.productName}
            />
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography fontWeight="bold">
                <span className="product-name">{productName}</span>
                {item.productName === 'MasterAddon' && (
                  <IconButton onClick={() => setShowEditModal(true)}>
                    <EditOutlinedIcon />
                  </IconButton>
                )}
              </Typography>
              {item.productName === 'MasterAddon' && (
              <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: '0' }}>
                {getAddonsList(cartAddons)?.map(addon => (
                  <Typography key={addon}>{camelCaseToFlat(addon)}</Typography>
                ))}
              </Box>
              )}
            </Box>
          </Box>
        </TableCell>
        <TableCell align="left">
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={typeOfSale}
            label=""
            onChange={typeOfSaleChange}
            IconComponent={ExpandMoreIcon}
            sx={{
              width: '250px',
              '& .MuiSelect-select': { textWrap: 'balance !important' },
              '.MuiOutlinedInput-notchedOutline': { borderStyle: 'none' },
            }}
          >
            {TypesOfSale.map(type => <MenuItem key={type.value} value={type.value}>{type.label}</MenuItem>)}
          </Select>
        </TableCell>
        <TableCell align="left">
          <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
            <AccessTimeIcon />
            <Typography marginLeft={1}>
              {formatDate(addWeeks(item.estimateDelivery, item.deliveryEstimateWeeks))}
            </Typography>
          </Box>
        </TableCell>
        <TableCell align="right">
          <IconButton classes={{ root: 'product-remove-btn' }} onClick={() => onRemove()}>
            <ClearIcon />
          </IconButton>
        </TableCell>
      </TableRow>
      {item.productName === 'MasterAddon' && (
      <EditAddonsModal
        open={showEditModal}
        handleClose={() => setShowEditModal(false)}
        addons={getAddonsList(cartAddons)}
        orderItemId={cartAddons.orderItemId}
      />
      )}
    </>
  );
};

export default CartItemRow;
