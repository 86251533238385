export const productNameMap = {
  'e-commerce microsite': 'E-Commerce Microsite',
  'website - landing page': 'Campaign Landing Page',
  'website - custom landing page': 'Custom Landing Page',
  'website - standard': 'Silver Website',
  'website - gold': 'Gold Website',
  'website - custom': 'Custom Website',
  'website - lite': 'Lite Websites',
  'premium listing': 'Premium Listings',
  chat: 'Smart Chatbot Silver',
  'chat - platinum': 'Platinum Chats',
  'master addons': 'Select Add-Ons',
  'add-ons': 'Add-Ons',
  'seo-standard': 'Standard SEO',
  'seo-custom': 'Custom SEO',
  'seo-blog-standard': 'Blog Content (Standard)',
  'seo-blog-enhanced': 'Blog Content (Enhanced)',
  'smm-standard': 'SMM Standard',
  'smm-custom': 'SMM Custom',
  'dmsnext-cc-by-dash': 'Customer Center By Dash',
  'dmsnext-cc': 'Customer Center',
  'seo-topic-location': 'SEO Topic/Location Add-on',
  'seo-content-pages': 'Content Pages Add-on',
  'smm-facebook-posts': 'SMM Facebook Boosted Posts',
  'smm-instagram': 'SMM Instagram',
  'smm-infographics': 'SMM Infographics',
  'smm-x': 'SMM X (Twitter)',
  'smm-pinterest': 'SMM Pinterest',
  'shopping-ads': 'Shopping Ads',
  'local-service-ads': 'Local Service Ads',
  'custom-reporting': 'Custom Reporting',
  'chat-spanish': 'Spanish Chat Add-on',
  'chat-hybrid': 'Chat Hybrid Addon',
  yelp: 'Yelp',
  'google-tag-manager': 'Google Tag Manager',
  'display-creative-services': 'Display Creative Services',
  'custom-solutions-spotify': 'Custom Solutions - Spotify',
  'custom-solutions-snapchat': 'Custom Solutions - Snapchat',
  'smb-retail-gci-only-responsive-site': 'SMB Retail GCI Only - Responsive Site',
  'custom-tracking': 'Custom Tracking',
  'custom-solutions-pandora': 'Custom Solutions - Pandora',
  'custom-solutions-tiktok': 'Custom Solutions - Tiktok',
  'custom-solutions-twitter': 'Custom Solutions - Twitter',
  'smb-retail-gci-only-smart-listings': 'SMB Retail GCI Only - SmartListings',
  'smb-retail-gci-only-visible-presence': 'SMB Retail GCI Only - Visible Presence',
  'video-creative-service-package': 'Video Creative Service Package',
  'site-retargeting-with-lookalikes-gdn': 'Site Retargeting With Lookalikes (GDN)',
  'smb-retail-gci-only-20-page-site': 'SMB Retail GCI Only - 20 Page Site',
  'smb-retail-gci-only-5-page-site': 'SMB Retail GCI Only - 5 Page Site',
  'targeted-email': 'Targeted Email',
  'social-ads-website-clicks': 'Social Ads Website Clicks',
  'rl-branded-content': 'RL Branded Content',
  'custom-solutions-linkedin': 'Custom Solutions - Linkedin',
  'social-ads-lead-ads-with-detailed-targeting': 'Social Ads Lead Ads with Detailed Targeting',
  'search-engine-marketing': 'Search Engine Marketing',
  'social-ads-lead-ads': 'Social Ads Lead Ads',
  youtube: 'YouTube',
  'targeted-display': 'Targeted Display',
  'targeted-display-legacy': 'Targeted Display Legacy',
  'social-ads-ad-engagement-add-on': 'Social Ads - Ad Engagement Add-On',
  'social-ads-custom': 'Social Ads - Custom',
  'social-ads-smart-optimization': 'Social Ads - Smart Optimization Technology',
  'social-ads-website-clicks-with-detailed-targeting': 'Social Ads Website Clicks with Detailed Targeting',
  'crm-integration-addon': 'CRM Integration Addon',
  'cs-waze-pilot': 'CS - Waze (Pilot)',
  'custom-campaign-landing-pages': 'Custom Campaign Landing Pages',
  'enhanced-reporting-add-on': 'Enhanced Reporting Add-On',
  'local-services-ads-management-services': 'Local Services Ads - Management Services',
  'national-sem': 'National - SEM',
  'performance-max-beta': 'Performance Max (PMAX BETA ONLY)',
  'website-managed-services': 'Website - Managed Services',
  'website-content-migration': 'Websites Content Migration',
  'premium-listings-ten-plus-physician-directories': 'Premium Listings 10 Plus Physician Directories',
  'premium-listings-10-plus': 'Premium Listings 10 Plus',
  'get-creative-service-fee': 'GetCreative Service Fee',
  'social-ads-retargeting': 'Social Ads Retargeting',
  'display-creative-services-custom': 'Display Creative Service - Custom',
  'website-add-on-content-pages': 'Website Add-On - Content Pages',
  xmo: 'XMO',
  'xmo-upgrade': 'XMO Upgrade',
  'promotions-page-and-creatives-reach-local-beta-only': 'Promotions Page And Creatives (ReachLocal Beta ONLY)',
  'promotions-media-mix': 'Promotions Media Mix',
  'aus-tiktok': 'AUS Tiktok',
  'aus-total-track': 'AUS Total Track',
  'aus-linkedin': 'AUS Linkedin',
  'aus-custom-facebook': 'AUS Custom Facebook',
  'aus-performance-max': 'AUS Performance Max',
  'aus-youtube': 'AUS Youtube',
  'aus-live-chat': 'AUS Live Chat',
  'multi-media-creatives': 'Multi-Media Creatives',
  'aus-demand-gen': 'AUS Demand Gen',
  'aus-site': 'AUS Site',
  'landing-pages-managed-services': 'Landing Pages Managed Services',
  'aus-video-creative-service': 'AUS Video Creative Service',
  'aus-google-display-network': 'AUS Google Display Network',
  'aus-premium-listings-1-9': 'AUS Premium Listings 1-9',
  'aus-social-catalogue': 'AUS Social Catalogue',
  'aus-xmo-social-upgrade': 'AUS XMO Social Upgrade',
  'aus-campaign-landing-pages': 'AUS Campaign Landing Pages',
  'aus-social-fb-conversions': 'AUS Social - FB Conversions',
};

export const siteMap = {
  'premium listing': 'PremiumListing',
  'master addons': 'MasterAddon',
  chat: 'ChatProduct',
  'chat - platinum': 'ChatPlatinumProduct',
};
