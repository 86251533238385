import { useEffect, useState } from 'react';
import { IQThemeV2 } from '@gannettdigital/shared-react-components';
import {
  Typography, Select, MenuItem, Grid,
  TextField, IconButton,
} from '@mui/material';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import ErrorText from 'components/errorText/ErrorText';
import GridItem from 'components/grid/GridItem';
import i18n from 'i18n/i18n';
import { Controller } from 'react-hook-form';
import schemaValidate from 'components/schemaValidate';
import { Trans } from 'react-i18next';
import { TypeOfSaleConstants } from 'pages/shopping-cart/Constants';
import { useSelector } from 'react-redux';
import { selectIsBlueFlow, selectIsPartnerFlow, selectOrdersContent } from 'services/ordersSlice';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { RenderBudgetField } from './Budget';
import { cyclesOptions } from '../generalQuestionsData';
import schema from '../GeneralQuestions.schema';
import { tooltip } from './Tracking';
import { OrderFlow } from "shared/constants";
import {selectActiveOfferingType} from "services/navigationSlice";

const { t } = i18n;

export const Details = (
  errors,
  register,
  control,
  budgetLimits,
  campaignName,
  campaignOwnerOptions,
  typeOfSale,
  setValue,
  watch,
  clearErrors,
  trigger,
  unregister
) => {
  const isBlueFlow = useSelector(selectIsBlueFlow);
  const [showEditButton, setShowEditButton] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const isPartnerFlow = useSelector(selectIsPartnerFlow);
  const {orderFlow} = useSelector(selectOrdersContent);
  const offeringType = useSelector(selectActiveOfferingType);
  const initialCyclesDisplayCreative= offeringType.includes('DisplayCreativeServices') || offeringType.includes('DisplayCreativeServiceCustom')
  useEffect(() => {
    if (isPartnerFlow || orderFlow === OrderFlow.INTERNAL) {
      unregister('campaignOwner');
    }
  }, [isPartnerFlow, orderFlow]);

  useEffect(() => {
    if (isBlueFlow) setShowEditButton(true);
  }, [isBlueFlow]);

  useEffect(() => {
    if (isEditing && campaignName) {
      setValue('campaignName', campaignName);
    }
  }, [isEditing, campaignName, control]);

  useEffect(() => {
    if (initialCyclesDisplayCreative) setValue('initialCycles', 1)
  }, [initialCyclesDisplayCreative])

  const handleEditButton = () => {
    setIsEditing(true);
    setShowEditButton(false);
    setValue('campaignName', campaignName);
  };
  return (
    <>
      <GridItem marginBottom={3}>
        <Typography variant="h5" component="h2">
          {t('pages.generalQuestions.details')}
        </Typography>
      </GridItem>

      <GridItem marginBottom={1} sizes={[8]}>
        <GridItem marginBottom={1}>
          <Typography variant="subtitle2" component="h3" fontSize={16}
            color={errors.campaignName ? IQThemeV2.palette.error.main : '#000000'}
          >
            {t('pages.generalQuestions.campaignName.title')}
          </Typography>
        </GridItem>
      </GridItem>

      <GridItem marginBottom={3} sizes={[8]}>
        {(!isEditing && campaignName && !isBlueFlow) ? (
          <GridItem marginBottom={0}>
            <GridItem marginBottom={1}>
              <Typography>{campaignName}</Typography>
            </GridItem>
          </GridItem>
        ) : (
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs={showEditButton ? 10 : 12}>
              {isEditing || !campaignName ? (
                <>
                  <Controller
                    name="campaignName"
                    control={control}
                    defaultValue={campaignName || ''}
                    rules={{
                      validate: (value) => schemaValidate(value, 'campaignName', schema),
                    }}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        onChange={onChange}
                        value={value}
                        hiddenLabel
                        required
                        id="campaignName"
                        name="campaignName"
                        fullWidth
                        error={!!errors.campaignName}
                      />
                    )}
                  />
                  <ErrorText
                    hasError={!!errors.campaignName}
                    errorText={t('pages.generalQuestions.campaignName.error')}
                  />
                </>
              ) : (
                <Typography>{campaignName}</Typography>
              )}
            </Grid>
            {showEditButton && (
            <Grid item xs={2}>
              <IconButton onClick={handleEditButton}>
                <EditOutlinedIcon />
              </IconButton>
            </Grid>
            )}
          </Grid>
        )}
      </GridItem>

      <GridItem sizes={[8]} marginBottom={3}>
        <GridItem marginBottom={1}>
          <Typography variant="subtitle2" component="h3" fontSize={16}
            color={errors.budget ? IQThemeV2.palette.error.main : '#000000'}
          >
            {t('pages.generalQuestions.budget.title')}
          </Typography>
          <GridItem marginBottom={0}>
            {RenderBudgetField(errors, register, budgetLimits, watch, setValue, clearErrors, trigger)}
          </GridItem>
        </GridItem>
      </GridItem>

      <GridItem sizes={[8]} marginBottom={3}>
        <GridItem marginBottom={1}>
          <Typography variant="subtitle2" component="h3" fontSize={16}
            color={errors.initialCycles ? IQThemeV2.palette.error.main : '#000000'}
          >
            {t('pages.generalQuestions.initialCycles.title')}
          </Typography>
          <GridItem marginBottom={0}>
            <Controller
              name="initialCycles"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Select
                  label=""
                  disabled={watch("autoRenew") === 'Auto Renew Off' || initialCyclesDisplayCreative}
                  displayEmpty
                  labelId="select-label"
                  error={!!errors.initialCycles}
                  {...field}
                  sx={{
                    width: '100%',
                  }}
                >
                  <MenuItem value="" disabled>
                    Select Cycles
                  </MenuItem>
                  {cyclesOptions.map(option => (
                    <MenuItem key={option} value={option}>
                      {option === 99 ? 'Unlimited' : option}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
            <ErrorText
              hasError={!!errors.initialCycles}
              errorText={t('pages.generalQuestions.initialCycles.error')}
            />
          </GridItem>
        </GridItem>
      </GridItem>

      {!isPartnerFlow && orderFlow !== OrderFlow.INTERNAL &&
          (<GridItem sizes={[8]} marginBottom={3}>
                <GridItem marginBottom={1}>
                  <Typography variant="subtitle2" component="h3" fontSize={16}
                              color={errors.campaignOwner ? IQThemeV2.palette.error.main : '#000000'}
                  >
                    {t('pages.generalQuestions.campaignOwner.title')}
                  </Typography>
                  <GridItem marginBottom={0}>
                    <Controller
                        name="campaignOwner"
                        control={control}
                        defaultValue=""
                        render={({field}) => (
                            <Select
                                label=""
                                displayEmpty
                                labelId="select-label"
                                error={!!errors.campaignOwner}
                                disabled={campaignOwnerOptions.length === 0}
                                {...field}
                                sx={{
                                  width: '100%',
                                }}
                            >
                              <MenuItem value="" disabled>
                                Select Campaign Owner
                              </MenuItem>
                              {campaignOwnerOptions.length > 0 && campaignOwnerOptions.map(option => (
                                  <MenuItem key={option.idBusinessUser}
                                            value={option.idBusinessUser}>
                                    {`${option.firstname} ${option.lastname}`}
                                  </MenuItem>
                              ))}
                            </Select>
                        )}
                    />
                    <ErrorText
                        hasError={!!errors.campaignOwner}
                        errorText={t('pages.generalQuestions.campaignOwner.error')}
                    />
                  </GridItem>
                </GridItem>
              </GridItem>
          )
      }

      {typeOfSale === TypeOfSaleConstants.BUDGET_REALLOCATION && (
        <GridItem sizes={[8]} marginBottom={3}>
          <GridItem marginBottom={1}>
            <Typography variant="subtitle2" component="h3" fontSize={16}
              color={errors.budgetReallocationCid ? IQThemeV2.palette.error.main : '#000000'}
            >
              {t('pages.generalQuestions.budgetReallocationCid.title')}
            </Typography>
            <GridItem marginBottom={0}>
              <TextField
                hiddenLabel
                required
                id="budgetReallocationCid"
                name="budgetReallocationCid"
                fullWidth
                error={!!errors.budgetReallocationCid}
                {...register('budgetReallocationCid', {
                  validate: (value) => schemaValidate(value, 'budgetReallocationCid', schema),
                })}
              />
              <ErrorText
                hasError={!!errors.budgetReallocationCid}
                errorText={t('pages.generalQuestions.budgetReallocationCid.error')}
              />
            </GridItem>
          </GridItem>
        </GridItem>
      )}
    </>
  );
};

export const DetailsRebuild = (errors, register) => (
  <>
    <GridItem marginBottom={3}>
      <Typography variant="h5" component="h2">
        {t('pages.generalQuestions.details')}
      </Typography>
    </GridItem>
    <GridItem sizes={[8]} marginBottom={3}>
      <GridItem marginBottom={1}>
        <Typography variant="subtitle2" component="h3" fontSize={16}
          color={errors.rebuildCid ? IQThemeV2.palette.error.main : '#000000'}
        >
          {t('pages.generalQuestions.rebuildCid.title')}
          {tooltip(<Trans
            i18nKey="pages.generalQuestions.rebuildCid.tooltip"
            components={{ br: <br /> }}
          />, <HelpOutlineOutlinedIcon />, '208px')}
        </Typography>
        <GridItem marginBottom={0}>
          <TextField
            hiddenLabel
            required
            id="rebuildCid"
            name="rebuildCid"
            fullWidth
            error={!!errors.rebuildCid}
            {...register('rebuildCid', {
              validate: (value) => schemaValidate(value, 'rebuildCid', schema),
            })}
          />
          <ErrorText
            hasError={!!errors.rebuildCid}
            errorText={t('pages.generalQuestions.rebuildCid.error')}
          />
        </GridItem>
      </GridItem>
    </GridItem>
  </>
);
