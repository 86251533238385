import i18n from 'i18n/i18n';

import {
  NotesAndInstructionsCustomValidate,
  NotesAndInstructionsSchema,
  NotesAndInstructionsUISchema
} from "pages/generic-product/details/product-schema/aus-product-schema/NotesAndInstructionsSchema";
import {
  TrackingCustomValidate,
  TrackingSchema,
  TrackingUISchema
} from "pages/generic-product/details/product-schema/aus-product-schema/TrackingSchema";
import {
  WebsiteConversionsQualificationCustomValidate,
  WebsiteConversionsQualificationSchema,
  WebsiteConversionsQualificationUISchema
} from "pages/generic-product/details/product-schema/aus-product-schema/social-facebook-conversions-schema/WebsiteConversionsQualificationSchema";
import {
  FacebookWebsiteConversionCustomValidate,
  FacebookWebsiteConversionSchema, FacebookWebsiteConversionUISchema
} from "pages/generic-product/details/product-schema/aus-product-schema/social-facebook-conversions-schema/FacebookWebsiteConversionSchema";
import {
  TargetingSocialFbConversionsCustomValidate,
  TargetingSocialFbConversionsSchema, TargetingSocialFbConversionsUISchema
} from "pages/generic-product/details/product-schema/aus-product-schema/social-facebook-conversions-schema/TargetingSocialFbConversionsSchema";
import {
  WebsiteConversionsCreativesCustomValidate,
  WebsiteConversionsCreativesSchema, WebsiteConversionsCreativesUISchema
} from "pages/generic-product/details/product-schema/aus-product-schema/social-facebook-conversions-schema/WebsiteConversionsCreativesSchema";
import {
  WebEventsCustomValidate,
  WebEventsSchema,
  WebEventsUISchema
} from "pages/generic-product/details/product-schema/aus-product-schema/WebEventsSchema";
import {
  CallTrackingCustomValidate,
  CallTrackingSchema,
  CallTrackingUISchema
} from "pages/generic-product/details/product-schema/aus-product-schema/CallTrackingSchema";
import {
  TrackingNumberDetailsCustomValidate,
  TrackingNumberDetailsSchema,
  TrackingNumberDetailsUISchema
} from "pages/generic-product/details/product-schema/aus-product-schema/TrackingNumberDetails";

const {t} = i18n;

export const AusSocialFbConversionsSchema = (data) => {
  const schema = [
    {
      type: 'object',
      title: t('pages.ausProduct.tracking.title'),
      schema: TrackingSchema(data),
      uiSchema: TrackingUISchema,
      customValidate: TrackingCustomValidate,
    },
    {
      type: 'object',
      title: t('pages.ausProduct.websiteConversionsQualification.title'),
      schema: WebsiteConversionsQualificationSchema(data),
      uiSchema: WebsiteConversionsQualificationUISchema,
      customValidate: WebsiteConversionsQualificationCustomValidate,
    },
    {
      type: 'object',
      title: t('pages.ausProduct.facebookWebsiteConversion.title'),
      schema: FacebookWebsiteConversionSchema(data),
      uiSchema: FacebookWebsiteConversionUISchema,
      customValidate: FacebookWebsiteConversionCustomValidate,
    },
    {
      type: 'object',
      title: t('pages.ausProduct.TargetingSocialFbConversions.title'),
      schema: TargetingSocialFbConversionsSchema(data),
      uiSchema: TargetingSocialFbConversionsUISchema,
      customValidate: TargetingSocialFbConversionsCustomValidate,
    },
    {
      type: 'object',
      title: t('pages.ausProduct.websiteConversionsCreatives.title'),
      schema: WebsiteConversionsCreativesSchema(data),
      uiSchema: WebsiteConversionsCreativesUISchema,
      customValidate: WebsiteConversionsCreativesCustomValidate,
    },
    {
      type: 'object',
      title: t('pages.ausProduct.notesAndInstructions.title'),
      schema: NotesAndInstructionsSchema(data),
      uiSchema: NotesAndInstructionsUISchema,
      customValidate: NotesAndInstructionsCustomValidate,
    },
  ];

  const trackingRequirements = data.trackingRequirements;
  const indexToInsert = 5;

  if (trackingRequirements === "Call and Web Event Tracking") {
    schema.splice(indexToInsert, 0, {
      type: 'object',
      title: t('pages.ausProduct.webEvents.title'),
      schema: WebEventsSchema(data),
      uiSchema: WebEventsUISchema(data),
      customValidate: WebEventsCustomValidate,
    });
    schema.splice(indexToInsert + 1, 0, {
      type: 'object',
      title: t('pages.ausProduct.callTracking.title'),
      schema: CallTrackingSchema(data),
      uiSchema: CallTrackingUISchema,
      customValidate: CallTrackingCustomValidate,
    });
    schema.splice(indexToInsert + 2, 0, {
      type: 'object',
      title: t('pages.ausProduct.trackingNumber1Details.title'),
      schema: TrackingNumberDetailsSchema(data),
      uiSchema: TrackingNumberDetailsUISchema(data),
      customValidate: TrackingNumberDetailsCustomValidate,
    });
  } else if (trackingRequirements === "Web Event Tracking") {
    schema.splice(indexToInsert, 0, {
      type: 'object',
      title: t('pages.ausProduct.webEvents.title'),
      schema: WebEventsSchema(data),
      uiSchema: WebEventsUISchema(data),
      customValidate: WebEventsCustomValidate,
    });
  } else if (trackingRequirements === "Call Tracking") {
    schema.splice(indexToInsert, 0, {
      type: 'object',
      title: t('pages.ausProduct.callTracking.title'),
      schema: CallTrackingSchema(data),
      uiSchema: CallTrackingUISchema,
      customValidate: CallTrackingCustomValidate,
    });
    schema.splice(indexToInsert + 1, 0, {
      type: 'object',
      title: t('pages.ausProduct.trackingNumber1Details.title'),
      schema: TrackingNumberDetailsSchema(data),
      uiSchema: TrackingNumberDetailsUISchema(data),
      customValidate: TrackingNumberDetailsCustomValidate,
    });
  }

  return schema;
};

export const AusSocialFbConversionsUISchema = (data) => ({
  'ui:order': [
    ...TrackingUISchema['ui:order'],
    ...WebsiteConversionsQualificationUISchema['ui:order'],
    ...FacebookWebsiteConversionUISchema['ui:order'],
    ...TargetingSocialFbConversionsUISchema['ui:order'],
    ...WebsiteConversionsCreativesUISchema['ui:order'],
    ...WebEventsUISchema(data)['ui:order'],
    ...CallTrackingUISchema['ui:order'],
    ...TrackingNumberDetailsUISchema(data)['ui:order'],
    ...NotesAndInstructionsUISchema['ui:order'],
  ],
});

export const AusSocialFbConversionsValidate = (formData: any, errors: any, uiSchema: any) => errors;
