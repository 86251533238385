import { Button } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { styled } from '@mui/material/styles';

export const CoButtonBackStyled = styled(Button)`
  &.Mui-disabled {
    color: #FFFFFF
  }
  text-transform: none;
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
  width: 139px;
  height: 60px; 
  font-family: 'Unify Sans';
  background-color: #FFFFFF;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
`;

export interface CoButtonBackProps {
  /**
   * Button label
   */
  label: string

  /**
   * Default value
   */
  disabled? : boolean

  [rest: string]: any

}

export function CoButtonBack({
  disabled = false,
  label = 'Back',
  ...rest
}: CoButtonBackProps) {
  return (
    <CoButtonBackStyled
      variant="outlined"
      color="primary"
      size="large"
      disabled={disabled}
      startIcon={<ArrowBackIosIcon />}
      {...rest}
    >
      {label}
    </CoButtonBackStyled>
  );
}

export default CoButtonBack;
