import React, {
  useState,
  useEffect,
  useRef,
  useCallback
} from "react";
import {
  TextField,
  Grid,
  Chip,
  Typography,
  CircularProgress,
  Box,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  useDispatch,
  useSelector
} from "react-redux";
import {
  saveCityTargetingDetails,
  selectCityTargetingDetails,
} from "services/cityTargetingdetailsSlice";
import { RootState } from "services/redux/store-schedule";
import AxiosAPI from "services/common/axios";
import { t } from "i18next";
import { selectCurrentCountry } from "services/topNavMenuSlice";
import Autocomplete from "@mui/material/Autocomplete";
import ErrorIcon from "@mui/icons-material/Error";

export default function CityTargetingDetails(props: any) {
  const dispatch = useDispatch();
  const savedTargetingDetails = useSelector((state: RootState) =>
      selectCityTargetingDetails(state)
  );
  const currentCountry = useSelector(selectCurrentCountry);
  const [addressData, setAddressData] = useState<any[]>([]);
  const [items, setItems] = useState<any>(
      savedTargetingDetails.cityTargetingDetails || []
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<boolean>(false);
  const [itemExistErrorMessage, setItemExistErrorMessage] = useState<boolean>(false);
  const debounceTimer = useRef<any>(null);
  const [inputValue, setInputValue] = useState<any>("");
  const { data } = props;

  useEffect(() => {
    if (data?.cityTargetingDetails) {
      setItems(data.cityTargetingDetails);
    }
  }, [data]);

  useEffect(() => {
    if (items) {
      dispatch(
          saveCityTargetingDetails({
            cityTargetingDetails: items,
            cityTargetingDetailsFlag: true,
          })
      );
    }
  }, [items, dispatch]);

  const fetchCityData = useCallback(
      async (query: string) => {
        if (!query.trim()) {
          setAddressData([]);
          setErrorMessage(false);
          return;
        }
        setIsLoading(true);
        setErrorMessage(false);
        try {
          const countryCode = currentCountry?.name || "";
          if (!query.includes(',')) {
            const response: any = await AxiosAPI.get(
                `/public/city/fetchGeoTargetIdForCity?country=${countryCode}&city=${query}`
            );
            if (response.status === 200) {
              const data = response.data;
              const filteredData = data.filter((address: any) =>
                  !items.some((item: any) => item?.geoTargetId === address?.geoTargetId)
              );
              setAddressData(filteredData);
            }
            setItemExistErrorMessage(false);
          }
        } catch (error) {
          setItemExistErrorMessage(false);
          setErrorMessage(true);
        } finally {
          setIsLoading(false);
        }
      },
      [currentCountry, items]
  );

  const handleInputChange = (event: any, value: string, type: any) => {
    if (type === "clear") {
      setInputValue("");
      setAddressData([]);
    }
    if (type === "input") {
      setInputValue(value);
      if (debounceTimer.current) {
        clearTimeout(debounceTimer.current);
      }
      debounceTimer.current = setTimeout(() => {
        fetchCityData(value);
      }, 500);
    }
  };

  const handleCitySelect = (selectedCity: any) => {
    if (!selectedCity) {
      return;
    }

    if (items.some((item: any) => item?.geoTargetId === selectedCity?.geoTargetId)) {
      setItemExistErrorMessage(true);
      return;
    }

    setItems((prevItems) => [...prevItems, selectedCity]);
    setItemExistErrorMessage(false);
  };

  useEffect(() => {
    const filteredData = addressData.filter((address: any) =>
        !items.some((item: any) => item?.geoTargetId === address?.geoTargetId)
    );
    setAddressData(filteredData);
  }, [items]);

  const handleDelete = (index: number) => {
    setItems((prevItems) => prevItems.filter((_, i) => i !== index));
  };

  return (
      <Grid container direction="column" spacing={2} mt={1}>
        <Grid item>
          <Typography
              variant="subtitle1"
              sx={{fontSize: "16px", fontWeight: "600"}}
          >
            {t("pages.localServiceAds.targetingType.cityTargetingDetailsLabel")}
          </Typography>
        </Grid>
        <Grid item>
          <Autocomplete
              freeSolo
              inputValue={inputValue}
              options={addressData}
              value={null}
              getOptionLabel={(option) =>
                  addressData.length > 0 ? option?.city + ", " + option?.state : ""
              }
              onInputChange={handleInputChange}
              onChange={(event: any, value: any) => {
                if (value && typeof value !== "string") {
                  handleCitySelect(value);
                }
              }}
              renderInput={(params) => (
                  <TextField
                      {...params}
                      placeholder="Search Cities"
                      variant="outlined"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                              {isLoading ? <CircularProgress size={20}/> : null}
                              {params.InputProps.endAdornment}
                            </>
                        ),
                      }}
                  />
              )}
          />
          {
              errorMessage &&
              <Box mt={1} sx={{display: 'flex', alignItems: 'center', color: '#C20F1E'}}>
                <ErrorIcon
                    sx={{
                      fontSize: '16px',
                      marginRight: '5px',
                    }}
                />
                <Typography
                    variant="subtitle1"
                    sx={{
                      fontSize: '14px',
                    }}>
                  {t('pages.localServiceAds.targetingType.cityTargetingDetailsErrorMessage')}
                </Typography>
              </Box>
          }
          {
              itemExistErrorMessage &&
              <Box mt={1} sx={{display: 'flex', alignItems: 'center', color: '#C20F1E'}}>
                <ErrorIcon
                    sx={{
                      fontSize: '16px',
                      marginRight: '5px',
                    }}
                />
                <Typography
                    variant="subtitle1"
                    sx={{
                      fontSize: '14px',
                    }}>
                  {t('pages.localServiceAds.targetingType.cityTargetingDetailsAlreadyAddedErrorMessage')}
                </Typography>
              </Box>
          }
        </Grid>
        <Grid item>
          <Box display="flex" alignItems="center" flexWrap="wrap" gap={1}>
            {items.map((item, index) => (
                <Chip
                    key={index}
                    label={`${item.city}, ${item.state}`}
                    onDelete={() => handleDelete(index)}
                    deleteIcon={
                      <CloseIcon style={{color: "#fff", fontSize: "18px"}}/>
                    }
                    sx={{
                      backgroundColor: "#0053F0",
                      color: "#fff",
                      fontSize: "14px",
                    }}
                />
            ))}
          </Box>
        </Grid>
      </Grid>
  );
}