/* eslint-disable max-len */
import i18n from 'i18n/i18n';
import {RJSFSchema} from '@rjsf/utils';
import {Grid, Typography} from "@mui/material";
import React from "react";

const {t} = i18n;

const validateRequiredFormField = (formData, fieldName, errors) => {
  if (formData?.[fieldName] === '') {
    errors[fieldName].addError(t('pages.ausProduct.tracking.requiredFieldErrorMessage'));
    errors.addError(true);
  }
};

export const WebsiteConversionsCreativesSchema: RJSFSchema | any = (data) => ({
  title: t('pages.ausProduct.websiteConversionsCreatives.title'),
  type: 'object',
  required: [
    'doesCsmHaveCreativeFreedom',
  ],
  properties: {
    creativeType: {
      type: 'array',
      title: t('pages.ausProduct.websiteConversionsCreatives.creativeTypeLabel'),
      items: {
        type: 'string',
        enum: [
          t('pages.ausProduct.websiteConversionsCreatives.singleImageOpt'),
          t('pages.ausProduct.websiteConversionsCreatives.carouselImageOpt'),
        ],
      },
      uniqueItems: true,
      minItems: 1,
    },
    doesCsmHaveCreativeFreedom: {
      type: 'string',
      title: t('pages.ausProduct.websiteConversionsCreatives.doesCsmHaveCreativeFreedomLabel'),
      default: data?.doesCsmHaveCreativeFreedom || '',
      enum: [
        '',
        t('pages.ausProduct.websiteConversionsCreatives.yesOpt'),
        t('pages.ausProduct.websiteConversionsCreatives.noOpt'),
      ],
    },
  },

  dependencies: {
    creativeType: {
      allOf: [
        {
          if: {
            properties: {
              creativeType: {
                contains: {
                  enum: [
                    t('pages.ausProduct.websiteConversionsCreatives.singleImageOpt'),
                  ],
                },
              },
            },
          },
          then: {
            properties: {
              SingleImageVideoHeading: {
                type: 'string',
              },
              topText: {
                type: 'string',
                title: t('pages.ausProduct.websiteConversionsCreatives.topTextLabel'),
                default: data?.topText || '',
              },
              headline: {
                type: 'string',
                title: t('pages.ausProduct.websiteConversionsCreatives.headlineLabel'),
                default: data?.headline || '',
              },
              newsfeedDescription: {
                type: 'string',
                title: t('pages.ausProduct.websiteConversionsCreatives.newsfeedDescriptionLabel'),
                default: data?.newsfeedDescription || '',
              },
              adTextAndUspInfo: {
                type: 'string',
                title: t('pages.ausProduct.websiteConversionsCreatives.adTextAndUspInfoLabel'),
                default: data?.adTextAndUspInfo || '',
              },
            },
          },
        },
        {
          if: {
            properties: {
              creativeType: {
                contains: {
                  enum: [t('pages.ausProduct.websiteConversionsCreatives.carouselImageOpt')],
                },
              },
            },
          },
          then: {
            properties: {
              platformForAds: {
                type: 'array',
                title: t('pages.ausProduct.websiteConversionsCreatives.platformForAdsLabel'),
                items: {
                  type: 'string',
                  enum: [
                    t('pages.ausProduct.websiteConversionsCreatives.facebookOpt'),
                    t('pages.ausProduct.websiteConversionsCreatives.instagramOpt'),
                    t('pages.ausProduct.websiteConversionsCreatives.audienceNetworkOpt'),
                    t('pages.ausProduct.websiteConversionsCreatives.messengerOpt'),
                  ],
                },
                uniqueItems: true,
                minItems: 1,
              },
              clientAdExactRequest: {
                type: 'string',
                title: t('pages.ausProduct.websiteConversionsCreatives.clientAdExactRequestLabel'),
                default: data?.clientAdExactRequest || '',
                enum: [
                  '',
                  t('pages.ausProduct.websiteConversionsCreatives.clientAdExactRequestYesOpt'),
                  t('pages.ausProduct.websiteConversionsCreatives.noOpt'),
                ],
              },
              allCreativesRequiredForCampaign: {
                type: 'string',
                title: t('pages.ausProduct.websiteConversionsCreatives.allCreativesRequiredForCampaignLabel'),
                default: data?.allCreativesRequiredForCampaign || '',
                enum: [
                  '',
                  t('pages.ausProduct.websiteConversionsCreatives.allCreativesRequiredForCampaignYesOpt'),
                  t('pages.ausProduct.websiteConversionsCreatives.allCreativesRequiredForCampaignNoOpt'),
                ],
              },
              carouselImageVideoHeading: {
                type: 'string',
              },
              topTextForCarouselImagesOrVideos: {
                type: 'string',
                title: t('pages.ausProduct.websiteConversionsCreatives.topTextLabel'),
                default: data?.topTextForCarouselImagesOrVideos || '',
              },
              headlineForCarouselImagesOrVideos: {
                type: 'string',
                title: t('pages.ausProduct.websiteConversionsCreatives.headlineLabel'),
                default: data?.headlineForCarouselImagesOrVideos || '',
              },
              newsfeedDescriptionForCarouselImagesOrVideos: {
                type: 'string',
                title: t('pages.ausProduct.websiteConversionsCreatives.newsfeedDescriptionLabel'),
                default: data?.newsfeedDescriptionForCarouselImagesOrVideos || '',
              },
              adTextAndUspInfoForCarouselImagesOrVideos: {
                type: 'string',
                title: t('pages.ausProduct.websiteConversionsCreatives.adTextAndUspInfoLabel'),
                default: data?.adTextAndUspInfoForCarouselImagesOrVideos || '',
              },
              helpText: {
                type: 'string',
                title: t(''),
                description: t('pages.ausProduct.websiteConversionsCreatives.helpText'),
              },
              additionalSetUpComments: {
                type: 'string',
                title: t('pages.ausProduct.websiteConversionsCreatives.additionalSetUpCommentsLabel'),
                default: data?.additionalSetUpComments || '',
              },
            },
            required: [
              'clientAdExactRequest',
              'allCreativesRequiredForCampaign',
              'additionalSetUpComments',
            ],
          },
        },
      ],
    },
  }
});

const checkBoxFieldKeys = [
  'creativeType',
  'platformForAds',
];

const inputFieldKeys = [
  'topText',
  'headline',
  'newsfeedDescription',
  'adTextAndUspInfo',
  'topTextForCarouselImagesOrVideos',
  'headlineForCarouselImagesOrVideos',
  'newsfeedDescriptionForCarouselImagesOrVideos',
  'adTextAndUspInfoForCarouselImagesOrVideos',
  'additionalSetUpComments',
];

const dropdownFieldKeys = [
  'doesCsmHaveCreativeFreedom',
  'clientAdExactRequest',
  'allCreativesRequiredForCampaign',
];

const checkBoxField = checkBoxFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: {
    'ui:widget': 'checkBoxField'
  },
}), {});

const inputFields = inputFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: {'ui:widget': 'textInputField'},
}), {});

const dropdownFields = dropdownFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: {'ui:widget': 'dropdown'},
}), {});

export const WebsiteConversionsCreativesUISchema = {
  'ui:order': [
    'creativeType',
    'doesCsmHaveCreativeFreedom',
    'SingleImageVideoHeading',
    'topText',
    'headline',
    'newsfeedDescription',
    'adTextAndUspInfo',
    'carouselImageVideoHeading',
    'platformForAds',
    'clientAdExactRequest',
    'allCreativesRequiredForCampaign',
    'topTextForCarouselImagesOrVideos',
    'headlineForCarouselImagesOrVideos',
    'newsfeedDescriptionForCarouselImagesOrVideos',
    'adTextAndUspInfoForCarouselImagesOrVideos',
    'helpText',
    'additionalSetUpComments',
  ],

  helpText:{
    'ui:widget': 'textLabel'
  },

  SingleImageVideoHeading: {
    'ui:widget': (props) => (
        <Grid item xs={9} mt={1}>
          <Typography fontSize={18} sx={{fontWeight: 'bold', mb: 1}}>
            {t('pages.ausProduct.websiteConversionsCreatives.SingleImageVideoHeading')}
          </Typography>
          {[
            t('pages.ausProduct.websiteConversionsCreatives.helpText1'),
            t('pages.ausProduct.websiteConversionsCreatives.helpText2'),
            t('pages.ausProduct.websiteConversionsCreatives.helpText3'),
            t('pages.ausProduct.websiteConversionsCreatives.helpText4'),
            t('pages.ausProduct.websiteConversionsCreatives.helpText5'),
            t('pages.ausProduct.websiteConversionsCreatives.helpText6'),
          ].map((text, index) => (
              <Typography key={index} fontSize={15}>
                {text}
              </Typography>
          ))}
        </Grid>
    ),
  },

  carouselImageVideoHeading: {
    'ui:widget': (props) => (
        <Grid item xs={9} mt={1}>
          <Typography fontSize={18} sx={{fontWeight: 'bold', mb: 1}}>
            {t('pages.ausProduct.websiteConversionsCreatives.carouselImageVideoHeading')}
          </Typography>
          {[
            t('pages.ausProduct.websiteConversionsCreatives.helpText1'),
            t('pages.ausProduct.websiteConversionsCreatives.helpText2'),
            t('pages.ausProduct.websiteConversionsCreatives.helpText3'),
            t('pages.ausProduct.websiteConversionsCreatives.helpText4'),
            t('pages.ausProduct.websiteConversionsCreatives.helpText5'),
            t('pages.ausProduct.websiteConversionsCreatives.helpText6'),
          ].map((text, index) => (
              <Typography key={index} fontSize={15}>
                {text}
              </Typography>
          ))}k
        </Grid>
    ),
  },

  ...checkBoxField,
  ...inputFields,
  ...dropdownFields,
};

export const WebsiteConversionsCreativesCustomValidate = (formData: any, errors, uiSchema) => {
  validateRequiredFormField(formData, 'doesCsmHaveCreativeFreedom', errors,);
  if (formData.creativeType.toString() === t('pages.ausProduct.websiteConversionsCreatives.carouselImageOpt')) {
    validateRequiredFormField(formData, 'clientAdExactRequest', errors,);
    validateRequiredFormField(formData, 'allCreativesRequiredForCampaign', errors,);
    validateRequiredFormField(formData, 'additionalSetUpComments', errors,);
  }
  return errors;
};