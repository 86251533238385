/*
"While using this component, please add 'schedule' to the ui:order field on
your product to render this component's value on the overview page.
Otherwise, the value of this scheduler won't be available on the overview page."
*
* */

import React, { useEffect, useState } from 'react';
import {
  Checkbox, FormControlLabel, Grid, TextField, Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { saveSchedule, selectSchedule } from 'services/schedulerSlice';
import { RootState } from 'services/redux/store-schedule';

const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

const SchedulerWidget = (props: any) => {
  const dispatch = useDispatch();
  const savedSchedule = useSelector((state: RootState) => selectSchedule(state));
  const { data } = props;

  // Initialize schedule state with saved schedule if it exists and is not null, otherwise initialize with empty values
  const [schedule, setSchedule] = useState(() => {
    if (savedSchedule && savedSchedule.length > 0 && savedSchedule[0]) {
      return savedSchedule[0];
    }
    return daysOfWeek.reduce((acc, day) => ({
      ...acc,
      [day]: {
        open: false, openTime: '', closeTime: '', fullDay: false,
      },
    }), {});
  });

  useEffect(() => {
    if (data && data.schedule && data.schedule.length > 0) {
      setSchedule(data.schedule[0]);
    }
  }, [data]);

  useEffect(() => {
    if (savedSchedule && savedSchedule.length > 0) {
      setSchedule(savedSchedule[0]);
    }
  }, [savedSchedule]);

  useEffect(() => {
    dispatch(saveSchedule({schedule: [schedule], scheduleFlag: true}));
  }, [schedule, dispatch]);

  const handleChange = (day, field, value) => {
    setSchedule((prevSchedule) => {
      const newSchedule = {...prevSchedule};
      if (field === 'fullDay' && value) {
        newSchedule[day] = {
          ...newSchedule[day],
          openTime: '00:00',
          closeTime: '00:00',
          [field]: value,
        };
      } else if (field === 'open' && !value) {
        newSchedule[day] = {
          open: false,
          openTime: '',
          closeTime: '',
          fullDay: false,
        };
      } else {
        newSchedule[day] = {
          ...newSchedule[day],
          [field]: value,
        };
      }
      return newSchedule;
    });
  };

  return (
    <Grid my={3}>
      <Typography variant="h4" mb={3} gutterBottom>
        {props.label}
      </Typography>
      {daysOfWeek.map((day) => (
        <Grid container spacing={3} mb={1}
          alignItems="center" key={day}
        >
          <Grid item xs={2}>
            <FormControlLabel
              control={(
                <Checkbox
                  checked={schedule[day].open}
                  onChange={(e) => handleChange(day, 'open', e.target.checked)}
                  color="primary"
                />
                    )}
              label={day}
            />
          </Grid>
          {schedule[day].open && (
          <>
            <Grid item xs={3}>
              <TextField
                label="Open Time"
                type="time"
                value={schedule[day].openTime}
                onChange={(e) => handleChange(day, 'openTime', e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: 300,
                }}
                fullWidth
                disabled={schedule[day].fullDay}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                label="Close Time"
                type="time"
                value={schedule[day].closeTime}
                onChange={(e) => handleChange(day, 'closeTime', e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: 300,
                }}
                fullWidth
                disabled={schedule[day].fullDay}
              />
            </Grid>
            <Grid item xs={2}>
              <FormControlLabel
                control={(
                  <Checkbox
                    checked={schedule[day].fullDay}
                    onChange={(e) => handleChange(day, 'fullDay', e.target.checked)}
                    color="primary"
                  />
                          )}
                label="24 Hours"
              />
            </Grid>
          </>
          )}
        </Grid>
      ))}
    </Grid>
  );
};

export default SchedulerWidget;