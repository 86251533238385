/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable import/no-extraneous-dependencies */
import { RJSFSchema } from '@rjsf/utils';
import i18n from 'i18n/i18n';
import Dropdown from 'pages/generic-product/details/custom-fields/Dropdown';
import {
  validateAgeRange,
} from 'pages/generic-product/details/product-schema/SocialAdsWithSmartOptimizationTechnologyChildSchema';

const { t } = i18n;

const radiusMileOrKilometerDistanceListService = () => {
  const radiusMileOrKilometerDistanceList = [];
  let index = 10;
  while (index <= 100) {
    radiusMileOrKilometerDistanceList.push(index.toString());
    index += 5;
  }
  return radiusMileOrKilometerDistanceList;
};

const ageEnum = Array.from({ length: 66 }, (_, i) => (i === 65 ? '65 Plus' : (i + 1).toString()));

export const SpecialAdCategoriesSchema: RJSFSchema | any = (data) => ({
  type: 'object',
  required: ['facebookSpecialAdCategories', 'targetingType'],
  properties: {
    specialAdCategoriesTitle: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.specialAdCategories.title'),
    },
    specialAdCategoriesDescription: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.specialAdCategories.description'),
    },
    facebookSpecialAdCategories: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.specialAdCategories.facebookSpecialAdCategories'),
      default: '',
      enum: [
        t('pages.socialAds.solutionStrategy.specialAdCategories.inputValues.yesCareerEmploymentValue'),
        t('pages.socialAds.solutionStrategy.specialAdCategories.inputValues.yesAutoFinanceCreditValue'),
        t('pages.socialAds.solutionStrategy.specialAdCategories.inputValues.yesHousingRealEstateValue'),
        t('pages.socialAds.solutionStrategy.specialAdCategories.inputValues.noNoneOfTheAboveValue'),
      ],
    },
    targetingLabel: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.targeting.title'),
    },
    targetingType: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.targeting.targetingTypeLabel'),
      description: t('pages.socialAds.solutionStrategy.targeting.targetingTypeDescription'),
      default: '',
      enum: [
        t('pages.socialAds.solutionStrategy.specialAdCategories.inputValues.countryValue'),
        t('pages.socialAds.solutionStrategy.specialAdCategories.inputValues.radiusValue'),
        t('pages.socialAds.solutionStrategy.specialAdCategories.inputValues.cityValue'),
        t('pages.socialAds.solutionStrategy.specialAdCategories.inputValues.dmaValue'),
        t('pages.socialAds.solutionStrategy.specialAdCategories.inputValues.zipCodeValue'),
      ],
    },
  },
  dependencies: {
    targetingType: {
      oneOf: [
        {
          properties: {
            targetingType: {
              enum: [t('pages.socialAds.solutionStrategy.specialAdCategories.inputValues.radiusValue')],
            },
            geoTargetingDetails: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.targeting.geoTargetingDetailsLabel'),
              variant: "h4"
            },
            advertiserBusinessAddress: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.targeting.advertiserBusinessAddressLabel'),
              default: '',
              enum: [
                t('pages.socialAds.solutionStrategy.specialAdCategories.inputValues.yesInputField'),
                t('pages.socialAds.solutionStrategy.specialAdCategories.inputValues.noInputField'),
              ],
            },
            radiusMileOrKilometerDistance: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.targeting.radiusMileOrKilometerDistanceLabel'),
              enum: radiusMileOrKilometerDistanceListService(),
            },
          },
          required: ["radiusMileOrKilometerDistance", "advertiserBusinessAddress"],
        },
        {
          properties: {
            targetingType: {
              enum: [
                t('pages.socialAds.solutionStrategy.specialAdCategories.inputValues.countryValue'),
                t('pages.socialAds.solutionStrategy.specialAdCategories.inputValues.cityValue'),
                t('pages.socialAds.solutionStrategy.specialAdCategories.inputValues.dmaValue'),
                t('pages.socialAds.solutionStrategy.specialAdCategories.inputValues.zipCodeValue'),
              ],
            },
            additionalGeoDetails: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.targeting.additionalGeoDetails'),
              default: '',
            },
          },
        }
      ],
    },
    advertiserBusinessAddress: {
      oneOf: [
        {
          properties: {
            advertiserBusinessAddress: {
              enum: [t('pages.socialAds.solutionStrategy.specialAdCategories.inputValues.noInputField')],
            },
            addressToUse: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.targeting.listTheAddressToUse'),
              default: '',
            },
          },
        },
      ],
    },
    facebookSpecialAdCategories: {
      oneOf: [
        {
          properties: {
            facebookSpecialAdCategories: {
              enum: [t('pages.socialAds.solutionStrategy.specialAdCategories.inputValues.noNoneOfTheAboveValue')],
            },
            minimumAge: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.targeting.minimumAgeLabel'),
              enum: [...ageEnum],
            },
            maximumAge: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.targeting.maximumAgeLabel'),
              enum: [...ageEnum],
            },
            gender: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.targeting.genderLabel'),
              enum: [
                t('pages.socialAds.solutionStrategy.specialAdCategories.inputValues.maleValue'),
                t('pages.socialAds.solutionStrategy.specialAdCategories.inputValues.femaleValue'),
                t('pages.socialAds.solutionStrategy.specialAdCategories.inputValues.bothValue'),
              ],
            },
            audienceTargetingDetails: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.targeting.audienceTargetingDetailsLabel'),
            },
          },
        },
      ],
    },
  },
});

const headingFieldKeys = [
  'specialAdCategoriesTitle',
  'targetingLabel',
];

const headingFields = headingFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'headingTextLabel' },
}), {});

const dropdownFieldKeys = [
  'facebookSpecialAdCategories',
  'targetingType',
  'radiusMileOrKilometerDistance',
  'minimumAge',
  'gender',
];

const dropdownFields = dropdownFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'dropdown' },
}), {});

const inputFieldKeys = [
  'audienceTargetingDetails',
  'additionalGeoDetails',
];

const inputFields = inputFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'textInputField' },
}), {});

export const SpecialAdCategoriesUISchema = {
  'ui:order': ['specialAdCategoriesTitle', 'specialAdCategoriesDescription', 'facebookSpecialAdCategories',
    'targetingLabel', 'targetingType', 'geoTargetingDetails', 'advertiserBusinessAddress', 'additionalGeoDetails',
    'radiusMileOrKilometerDistance', 'addressToUse', 'minimumAge', 'maximumAge', 'gender', 'audienceTargetingDetails'],
  specialAdCategoriesDescription: { 'ui:widget': 'textLabel' },
  geoTargetingDetails: {
    'ui:widget': 'textLabel',
    'ui:style': {
      marginTop: '20px',
      marginBottom: '-10px',
    }
  },
  maximumAge: {
    'ui:widget': (props) => (
      <Dropdown {...props}
        errorMessage={t('pages.socialAds.solutionStrategy.specialAdCategories.maximumAgeErrorMessage')}
      />
    ),
  },
  advertiserBusinessAddress: {
    'ui:widget': (props) => <Dropdown {...props} required />,
  },
  addressToUse: {
    'ui:widget': 'addressField',
  },
  ...headingFields,
  ...dropdownFields,
  ...inputFields,
};

export const SpecialAdCategoriesCustomValidate = (formData, errors, uiSchema) => {
  if (t('pages.socialAds.solutionStrategy.specialAdCategories.inputValues.radiusValue')
    .includes(formData?.targetingType)) {
    if (formData?.advertiserBusinessAddress === '' || formData?.advertiserBusinessAddress === undefined) {
      // eslint-disable-next-line max-len
      errors?.advertiserBusinessAddress?.addError(t('pages.socialAds.solutionStrategy.targeting.advertiserBusinessAddressError'));
      errors.addError(true);
    }
  }
  validateAgeRange(formData, 'minimumAge', 'maximumAge', 'maximumAge', errors, t);
  return errors;
};
